import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {editSettingOperational, settingOperational} from 'containers/Setting/apiHelper';

export const fetchSettingOperational = createAsyncThunk('setting/fetchSettingOperational', async (_, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(settingOperational({cancelToken: source.token}));
  return response.data;
});

export const fetchEditSettingOperational = createAsyncThunk('setting/editSettingOperational', async arg => {
  const response = await api(editSettingOperational(arg));
  return response.data;
});

const extraReducers = {
  [fetchSettingOperational.pending]: (state, action) => {
    state.settingOperational = {...action.payload, loading: true};
  },
  [fetchSettingOperational.fulfilled]: (state, action) => {
    state.settingOperational = {...action.payload, loading: false};
  },
};

const name = 'settings';

const initialState = {
  settingOperational: {},
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
