export const BANK = {
  SCB: {CODE: '014', SHORT_NAME: 'SCB', NAME: 'ไทยพาณิชย์', COLOR: '#A6C'},
  KBANK: {CODE: '004', SHORT_NAME: 'KBANK', NAME: 'กสิกรไทย', COLOR: '#4CDC8A'},
  BAY: {CODE: '025', SHORT_NAME: 'BAY', NAME: 'กรุงศรีฯ', COLOR: '#FFD506'},
  BBL: {CODE: '002', SHORT_NAME: 'BBL', NAME: 'กรุงเทพ'},
  KTB: {CODE: '006', SHORT_NAME: 'KTB', NAME: 'กรุงไทย'},
  TTB: {CODE: '011', SHORT_NAME: 'TTB', NAME: 'ทหารไทยธนชาต (TTB)'},
  GSB: {CODE: '030', SHORT_NAME: 'GSB', NAME: 'ออมสิน (GSB)', COLOR: '#eb198d'},
  BAAC: {CODE: '034', SHORT_NAME: 'BAAC', NAME: 'ธกส (BAAC)'},
  UOB: {CODE: '024', SHORT_NAME: 'UOB', NAME: 'ยูโอบี'},
  GHB: {CODE: '033', SHORT_NAME: 'GHB', NAME: 'ธอส. (GHB)'},
  CIMB: {CODE: '022', SHORT_NAME: 'CIMB', NAME: 'ซีไอเอ็มบี'},
  CITI: {CODE: '017', SHORT_NAME: 'CITI', NAME: 'ซิตี้แบงก์'},
  DB: {CODE: '032', SHORT_NAME: 'DB', NAME: 'ดอยซ์แบงก์'},
  HSBC: {CODE: '031', SHORT_NAME: 'HSBC', NAME: 'เอชเอสบีซี'},
  ICBC: {CODE: '070', SHORT_NAME: 'ICBC', NAME: 'ไอซีบีซี'},
  ISBT: {CODE: '066', SHORT_NAME: 'ISBT', NAME: 'อิสลาม'},
  KK: {CODE: '069', SHORT_NAME: 'KK', NAME: 'เกียรตินาคินภัทร'},
  LH: {CODE: '073', SHORT_NAME: 'LH', NAME: 'แลนด์แอนด์เฮ้าส'},
  MHCB: {CODE: '039', SHORT_NAME: 'MHCB', NAME: 'มิซูโฮ'},
  SCBT: {CODE: '020', SHORT_NAME: 'SCBT', NAME: 'สแตนดาร์ดชาร์เตอร์'},
  SMBC: {CODE: '018', SHORT_NAME: 'SMBC', NAME: 'ซูมิโตโม มิตซุย'},
  TCRB: {CODE: '071', SHORT_NAME: 'TCRB', NAME: 'ไทยเครดิต'},
  TISCO: {CODE: '067', SHORT_NAME: 'TISCO', NAME: 'ทิสโก้'},
  TRUE: {CODE: '999', SHORT_NAME: 'TRUE', NAME: 'TRUE WALLET'},
};

export const BANK_COLOR = {
  SCB: '#4E2A82',
  KBANK: '#00a950',
  BAY: '#ffd400',
  KTB: '#05AEE2',
  NOTFOUND: '#FFA100',
  DEFAULT: '#ffffff',
};

export const BANK_TYPE = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  VERIFY: 'verify',
  CHECK_NAME: 'check_name',
};

export const BANK_MODE = {
  API: 'api', // The third party who support us
  HEADLESS: 'headless', // Only in SCB connect
};

export const scbOptions = [
  {
    NAME: 'SCB Easy App on Mobile',
    MODE: [BANK_MODE.API],
    TYPE: [BANK_TYPE.DEPOSIT, BANK_TYPE.WITHDRAW, BANK_TYPE.VERIFY],
  },
  {
    NAME: 'SCB Connect via Line',
    MODE: [BANK_MODE.HEADLESS],
    TYPE: [BANK_TYPE.DEPOSIT],
  },
];

export const kbankOptions = [
  {
    NAME: 'KPlus APP',
    MODE: [BANK_MODE.API],
    TYPE: [BANK_TYPE.DEPOSIT, BANK_TYPE.WITHDRAW],
    PROVIDER: BANK.KBANK.SHORT_NAME,
  },
  {
    NAME: 'KBiz',
    MODE: [BANK_MODE.API, BANK_MODE.HEADLESS], // api mode default
    TYPE: [BANK_TYPE.DEPOSIT],
  },
];

export const BANK_CHANNEL = {
  APP: {
    [BANK.SCB.SHORT_NAME]: {
      NAME: 'SCB Easy APP',
      DEFAULT_MODE: BANK_MODE.API,
      MODE: [BANK_MODE.API],
      TYPE: [BANK_TYPE.DEPOSIT, BANK_TYPE.WITHDRAW, BANK_TYPE.VERIFY],
      PROVIDER: BANK.SCB.SHORT_NAME,
      DISABLED: false,
    },
    // [BANK.KBANK.SHORT_NAME]: {
    //   NAME: 'KPlus APP',
    //   DEFAULT_MODE: BANK_MODE.API,
    //   MODE: [BANK_MODE.API],
    //   TYPE: [BANK_TYPE.DEPOSIT, BANK_TYPE.WITHDRAW],
    //   PROVIDER: BANK.KBANK.SHORT_NAME,
    //   DISABLED: false,
    // },
    // [BANK.GSB.SHORT_NAME]: {
    //   NAME: 'GSB APP',
    //   DEFAULT_MODE: BANK_MODE.API,
    //   MODE: [BANK_MODE.API],
    //   TYPE: [BANK_TYPE.WITHDRAW],
    //   PROVIDER: BANK.SCB.SHORT_NAME,
    //   DISABLED: false,
    // },
  },
  WEB: {
    [BANK.SCB.SHORT_NAME]: {
      NAME: 'SCB Connect via Line',
      DEFAULT_MODE: BANK_MODE.HEADLESS,
      MODE: [BANK_MODE.HEADLESS],
      TYPE: [BANK_TYPE.DEPOSIT],
      PROVIDER: BANK.SCB.SHORT_NAME,
      DISABLED: false,
    },
    [BANK.KBANK.SHORT_NAME]: {
      NAME: 'Kbiz Web',
      DEFAULT_MODE: BANK_MODE.API,
      MODE: [BANK_MODE.API],
      TYPE: [BANK_TYPE.DEPOSIT, BANK_TYPE.WITHDRAW],
      PROVIDER: BANK.SCB.SHORT_NAME,
      DISABLED: false,
    },
    [BANK.BAY.SHORT_NAME]: {
      NAME: 'Krungsri online',
      DEFAULT_MODE: BANK_MODE.HEADLESS,
      MODE: [BANK_MODE.HEADLESS],
      TYPE: [BANK_TYPE.DEPOSIT],
      PROVIDER: BANK.SCB.SHORT_NAME,
      DISABLED: true, // Do in future plan
    },
  },
};
