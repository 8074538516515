const initState = {
  logList: null,
  logListWithAction: null,
  logListWithAdmin: null,
  loading: true,
};

const logReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getLogList':
      var logs = action.res.data.data;
      var logDataList = [];

      logs?.forEach(log => {
        logDataList.push(log);
      });

      return {
        ...state,
        logList: logDataList,
        loading: false,
      };
    case 'getLogListWithAction':
      var logListWithAction = action.res.data.data;
      var logListWithActionData = [];

      logListWithAction.forEach(log => {
        logListWithActionData.push(log);
      });

      return {
        ...state,
        logListWithAction: logListWithActionData,
        loading: false,
      };
    case 'getLogListWithAdmin':
      var logListWithAdmin = action.res.data.data;
      var logListWithAdminData = [];

      logListWithAdmin.forEach(log => {
        logListWithAdminData.push(log);
      });

      return {
        ...state,
        logListWithAdmin: logListWithAdminData,
        loading: false,
      };
    default:
      return state;
  }
};
export default logReducer;
