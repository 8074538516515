import FileCopyIcon from '@material-ui/icons/FileCopy';
import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import Level from 'views/user/UserDetailView/Level';
import React, {useCallback, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {useClipboard} from 'use-clipboard-copy';

const User = props => {
  const {
    data,
    path: {username: usernamePath, level: levelPath},
  } = props;

  const username = get(data, usernamePath, '');
  const level = get(data, levelPath, 1);
  const [searchParams] = useSearchParams();
  const [paramLevel] = useState(searchParams.get('level') || 'all');

  const clipboard = useClipboard({
    copiedTimeout: 600,
    onError() {
      // console.log('Failed to copy text!');
    },
  });

  const handleClick = useCallback(() => {
    clipboard.copy(username);
  }, [clipboard.copy, username]);

  const userElement = () => <span className="cursor-pointer text-blue-400 hover:underline pr-1">{username}</span>;

  if (!username) {
    return null;
  }

  return (
    <div className="flex justify-center items-center">
      {clipboard.copied ? (
        <span className="text-purple-500">copied !</span>
      ) : (
        <>
          <div className="pr-2">{level >= 1 && <Level level={level} width={20} paramLevel={paramLevel} />}</div>
          <Link to={`/admin/userdetail/${username}`} target="_blank">
            {userElement()}
          </Link>
        </>
      )}
      {clipboard.isSupported() ? (
        clipboard.copied ? null : (
          <IconButton size="small" aria-label="copy" onClick={handleClick}>
            <FileCopyIcon style={{fontSize: 16, color: 'white'}} />
          </IconButton>
        )
      ) : null}
    </div>
  );
};

export default User;
