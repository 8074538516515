import {cn} from 'utils/css.util';

/**
 * Typography
 * @param {object} props
 * @param {string} props.children
 * @param {string} props.className
 * @returns {jsx}
 */
export const Typography = ({children, className}) => {
  return <h1 className={cn('text-lg font-semibold', className)}>{children}</h1>;
};
