import {useState} from 'react';

const Dragger = props => {
  const {onChange, children, multiple} = props;

  // eslint-disable-next-line no-unused-vars
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const files = event.dataTransfer.files;
    onChange(files);
  };

  const handleFileSelect = event => {
    const files = event.target.files;
    onChange(files);
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={() => {
        document.getElementById('file-input').click();
      }}>
      {children}
      <input
        id="file-input"
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleFileSelect}
        multiple={multiple}
        style={{display: 'none'}}
      />
    </div>
  );
};

export default Dragger;
