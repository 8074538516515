import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  createGame,
  createGameValue,
  editGame,
  editGameValue,
  gameById,
  games,
  gameValues,
} from 'containers/Setting/Game/apiHelper';

export const fetchGameList = createAsyncThunk('game/fetchList', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(games(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchGameById = createAsyncThunk('game/fetchGame', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(gameById(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchGameValueList = createAsyncThunk('gameValue/fetchList', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(gameValues(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchCreateGame = createAsyncThunk('game/fetchCreateGame', async arg => {
  const response = await api(createGame(arg));
  return response.data;
});

export const fetchCreateGameValue = createAsyncThunk('gameValue/fetchCreateGameValue', async arg => {
  const response = await api(createGameValue(arg));
  return response.data;
});

export const fetchEditGame = createAsyncThunk('game/fetchEditGame', async arg => {
  const response = await api(editGame(arg));
  return response.data;
});

export const fetchEditGameValue = createAsyncThunk('game/fetchEditGameValue', async arg => {
  try {
    const response = await api(editGameValue(arg));
    return response;
  } catch (err) {
    throw new Error(err?.data?.message);
  }
});

const extraReducers = {
  [fetchGameList.pending]: (state, action) => {
    state.gameList = {...action.payload, loading: true};
  },
  [fetchGameList.fulfilled]: (state, action) => {
    state.gameList = {...action.payload, loading: false};
  },
  [fetchGameById.pending]: (state, action) => {
    state.game = {...action.payload, loading: true};
  },
  [fetchGameById.fulfilled]: (state, action) => {
    state.game = {...action.payload, loading: false};
  },
  [fetchGameValueList.pending]: (state, action) => {
    state.gameValue = {...action.payload, loading: true};
  },
  [fetchGameValueList.fulfilled]: (state, action) => {
    state.gameValue = {...action.payload, loading: false};
  },
};

const initialState = {
  gameList: {},
  gameValue: {},
  game: {},
};

const name = 'game';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
