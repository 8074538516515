import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useClipboard} from 'use-clipboard-copy';

// toast.success(`คัดลอกเลขบัญชี ${bank?.bankId} แล้ว`, {
//   position: 'bottom-center',
//   autoClose: 3000,
//   hideProgressBar: true,
//   closeOnClick: true,
// });

const Copy = props => {
  const {text, goTo} = props;

  const clipboard = useClipboard({
    copiedTimeout: 600,
    onError() {
      // console.log('Failed to copy text!');
    },
  });

  const handleClick = useCallback(() => {
    clipboard.copy(text);
  }, [clipboard.copy, text]);

  const textElement = () => (
    <span className={`${typeof goTo === 'function' && 'cursor-pointer text-blue-400 hover:underline'} text-white px-1`}>
      {text}
    </span>
  );

  if (!text) return null;

  return (
    <div>
      {clipboard.copied ? (
        <span className="text-purple-500 px-1">copied !</span>
      ) : typeof goTo === 'function' ? (
        <Link to={goTo} target="_blank">
          {textElement()}
        </Link>
      ) : (
        textElement()
      )}
      {clipboard.isSupported() ? (
        clipboard.copied ? null : (
          <IconButton size="small" aria-label="copy" onClick={handleClick}>
            <FileCopyIcon style={{fontSize: 16, color: 'white'}} />
          </IconButton>
        )
      ) : null}
    </div>
  );
};

export default Copy;
