import moment from 'moment';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';

import {numberFormat} from 'utils/format-number.util';

import {fetchNearbyDepositTransactionByUserUuid, resetManualList} from 'store/deposit.slice';

import SimpleTable from 'components/shared/SimpleTable';
import Status from 'components/Deposit/Status';

import {Separator} from 'components/shared/Separator';
import {Typography} from 'components/shared/Typography';

const columnsDepositManual = [
  {
    id: 'id',
    label: '#',
    align: 'center',
  },
  {
    id: 'transactionDate',
    label: 'วันที่',
    align: 'center',
    width: 150,
    format: value => moment(value).format('DD/MM/YYYY HH:mm'),
  },
  {
    id: 'amount',
    label: 'จำนวน',
    align: 'right',
    format: value => numberFormat(value),
  },
  {
    id: 'bank',
    label: 'เข้าบัญชี',
    width: 100,
    format: value => `${value?.bankName}`,
  },
  {
    id: 'status',
    label: 'สถานะ',
    align: 'center',
    width: 105,
    // eslint-disable-next-line react/display-name
    format: (value, record) => {
      return <Status deposit={record} done={void 0} />;
    },
  },
];

export const NearbyDepositTransaction = ({userUuid, amount, transactionDate}) => {
  const dispatch = useDispatch();

  const nearbyTransaction = useSelector(state => get(state, 'deposit.nearbyDepositTransaction.data', []));

  useEffect(() => {
    let promise;
    if (!nearbyTransaction.length) {
      promise = dispatch(fetchNearbyDepositTransactionByUserUuid({userUuid, amount, transactionDate}));
    }

    return () => {
      promise && promise.abort();
      dispatch(resetManualList());
    };
  }, [userUuid, amount]);

  return (
    <div>
      <Typography className="mb-2 text-xl text-violet-500 ">รายการฝากที่ใกล้เคียง</Typography>
      <Separator />
      {nearbyTransaction?.length ? (
        <SimpleTable dataSource={nearbyTransaction} columns={columnsDepositManual} total={nearbyTransaction.length} />
      ) : (
        <div className="mt-2 py-3 border border-gray-600 rounded-md border-dashed">
          <Typography className="text-center font-normal text-sm">ไม่พบรายการฝากใกล้เคียงกัน</Typography>
        </div>
      )}
    </div>
  );
};

export default NearbyDepositTransaction;
