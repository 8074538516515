import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export const delay = (ms = 5000) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};

export const convertBankId = bankId => bankId.substring(6);

// TODO: Popup

export const popup = props => {
  return MySwal.fire({
    icon: 'question',
    confirmButtonText: 'ตกลง',
    timer: ['error', 'question'].includes(props.icon) ? 0 : 5000,
    background: '#121212',
    showCloseButton: true,
    focusConfirm: true,
    customClass: {
      popup: 'swal2-content',
      title: 'swal2-title',
    },
    ...props,
  });
};

export function isValidURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}
