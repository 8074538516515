export const DEPOSIT_STATUS = {
  FAIL: 0,
  SUCCESS: 1,
  NOT_FOUND: 3,
  TIMEOUT: 4,
  FAIL_THEN_SUCCESS: 5,
  TIMEOUT_THEN_SUCCESS: 6,
  NOTFOUND_THEN_SUCCESS: 7,
  MANUAL: 8,
  MANUAL_SLIP_ADMIN: 9,
  MANUAL_SLIP_USER: 10,
};

export const WITHDRAW_STATUS = {
  FAIL: 0,
  SUCCESS: 1,
  CANCEL: 2,
  MANUAL: 3,
};

export const CREDIT_BALANCE = {
  MAIN: 'M',
  ALL: 'A',
};

export const REWARD_CATEGORY = {
  CREDIT: '1',
  THING: '2',
};

export const REWARD_STATUS = {
  WAITING: 0,
  SUCCESS: 1,
  CANCEL: 2,
};

export const CLIENT_IDS = {
  GIMI88: 'GIMI88',
  GIMI168: 'GIMI168',
  GIMI44: 'GIMI44',
  GIMIXO: 'GIMIXO',
  GICC: 'GICC',
  CR77BET: 'CR77BET',
  EUROPA168: 'EUROPA168',
  FASTTOBET: 'FASTTOBET',
  GKBET888: 'GKBET888',
  PAP55: 'PAP55',
  ALFA555: 'ALFA555',
  IFINCLUB: 'IFINCLUB',
  REBOOST88: 'REBOOST88',
  AREA88BET: 'AREA88BET',
  XOBET98: 'XOBET98',
  PGLIKE: 'PGLIKE',
  SAIPAY69: 'SAIPAY69',
  CIGAR88: 'CIGAR88',
  JUMPERBET: 'JUMPERBET',
  INW88: 'INW88',
  MUMMY55: 'MUMMY55',
};
