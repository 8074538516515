import PropTypes from 'prop-types';

import {Dialog, DialogContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

const MainDialog = ({open, onClose, maxWidth, children, dialogClickOutsideToClose}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={dialogClickOutsideToClose ? onClose : null}
      fullWidth
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}>
      <DialogContent className={classes.paper}>{children}</DialogContent>
    </Dialog>
  );
};

MainDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
};

export default MainDialog;
