import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {Button, makeStyles} from '@material-ui/core';

import {popup} from 'utils/common.util';

import {closeDialog} from 'store/uiProvider.slice';
import {fetchRejectSlipRequest, fetchSlipHistoryList, fetchSlipImageUrl, fetchSlipRequestList} from 'store/slip.slice';

import Spinner from 'components/shared/Spinner';
import {SlipComparisonDetail} from 'components/Slip/SlipDetail';

const useStyles = makeStyles(() => ({
  cancelBtn: {
    background: '#C0C0C0',
    color: 'black',
  },
  confirmBtn: {
    backgroundColor: '#9A070B',
    color: 'white',
  },
}));

const RejectSlipRequestDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const slipRequest = useSelector(state => get(state, 'slip.slipRequest', {}));
  const slipImageUrl = useSelector(state => get(state, `slip.slipImageUrl`, ''));
  const imageUrl = slipImageUrl[slipRequest.fileName]?.data;

  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const handleRejectSlipRequest = async () => {
    setLoading(true);
    try {
      const response = await dispatch(fetchRejectSlipRequest({uuid: slipRequest.uuid}));
      await dispatch(fetchSlipRequestList());
      await dispatch(fetchSlipHistoryList());
      dispatch(closeDialog());
      popup({
        icon: 'success',
        title: response?.data?.data?.message || 'ยกเลิกรายการฝากด้วยสลิปสําเร็จ',
        timer: 2000,
      });
    } catch (err) {
      popup({
        icon: 'error',
        title: err?.message ?? 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
        timer: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const getImageUrl = async () => {
    await dispatch(fetchSlipImageUrl({fileName: slipRequest.fileName, userId: slipRequest.userUuid}));
  };

  useEffect(() => {
    if (!imageUrl) {
      getImageUrl();
    }
  }, [slipRequest]);

  return (
    <div>
      <div className="content">
        <h1 className="text-center text-xl text-[#9A070B]">
          <p>คุณต้องการที่จะยกเลิกรายการฝากด้วยสลิป</p>
          <p>รายการนี้ใช่หรือไหม ?</p>
        </h1>

        <div className="py-4">
          <div id="Slip" className="flex justify-center items-center">
            <img src={imageUrl} alt={'slip'} className="rounded-md w-96" />
          </div>

          <h3 className="text-red-500 text-center mt-2 mb-4">!! กรุณาตรวจสอบข้อมูลรายละเอียดก่อนลบรายการ</h3>

          <SlipComparisonDetail slipRequest={slipRequest} />
        </div>
      </div>
      <section id="aciton" className="grid grid-cols-2 gap-2">
        <Button className={classes.cancelBtn} onClick={handleCancel} disabled={loading}>
          ปิด
        </Button>
        <Button className={classes.confirmBtn} onClick={handleRejectSlipRequest} disabled={loading}>
          {loading ? <Spinner size={20} /> : 'ยืนยันการยกเลิก'}
        </Button>
      </section>
    </div>
  );
};

export default RejectSlipRequestDialog;
