export function base64VerifySlip(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/auto/slip/base64-verify',
    data: payload,
  };
}

export function getSlipRequestList({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/slip/request/list',
    cancelToken,
  };
}

/**
 * Get image url for slip
 * @param {object} payload
 * @param {string} payload.fileName
 * @param {string} payload.userId
 * @returns
 */
export function getSlipImageUrl(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/slip/image/${payload.fileName}`,
    params: {userId: payload.userId},
    cancelToken,
  };
}

/**
 * Approve slip request
 * @param {object} payload
 * @param {string} payload.uuid
 * @returns
 */
export function approveSlipRequest(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/slip/request/approve',
    data: payload,
  };
}

/**
 * Notfound slip request
 * @param {object} payload
 * @param {string} payload.uuid
 * @param {string} payload.username
 * @returns
 */
export function notfoundSlipRequest(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/slip/request/notfound',
    data: payload,
  };
}

/**
 * Manual slip request
 * @param {object} payload
 * @param {string} payload.uuid
 * @param {string} payload.transactionDate
 * @param {number} payload.amount
 * @param {string} payload.note
 * @returns
 */
export function manualSlipRequest(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/slip/request/manual',
    data: payload,
  };
}

/**
 * Reject slip request
 * @param {object} payload
 * @param {string} payload.uuid
 * @returns
 */
export function rejectSlipRequest(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/slip/request/reject',
    data: payload,
  };
}

/**
 *  Get slip history
 * @returns
 */
export function slipHistoryList({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/slip-history/list',
    cancelToken,
  };
}
