import shadows from './shadows';
import typography from './typography';
import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    background: {
      secondary: '#121212',
      dark: '#F4F6F8',
      black: '#000000', // rgb(59, 57, 99)
      grey1: '#121212', // rgb(52, 49, 86)
      grey2: '#161616', //  rgb(48, 45, 82)
      default: '#212121',
      paper: '#212121',
      tableLine: '#231E23', // rgba(121, 118, 160, 0.6)
      mainBtn: '#39A6A3', // rgb(69, 67, 120)
      error: '#ff3547',
      success: '#00B74A',
      timeout: '#ffbc33',
      failThenSuccess: '#35b4e4',
      timeoutThenSuccess: '#378d3c',
      notfoundThenSuccess: '#8cc34a',
      manualThenSuccess: '#B23CFD',
    },
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    danger: {
      main: '#ff3547',
      contrastText: '#fff',
    },
    default: {
      main: '#1976d2',
      contrastText: '#fff',
    },
    text: {
      primary: '#F9F9F9',
      secondary: '#E8F0F2', //  DBE6FD
    },
  },
  shadows,
  typography,
});

export default theme;
