import Axios from '../../utils/axiosConfig';
import {REWARD_CATEGORY} from '../../constants';

export const getSettingDetail = dispatch => {
  Axios.get('/secure/setting').then(res => {
    if (res.data.success) {
      dispatch({type: 'clearError'});
      dispatch({type: 'getSettingDetail', res});
    } else {
      dispatch({type: 'onError', res});
    }
  });
};

export const getRewardSettingList = dispatch => {
  Axios.get('/secure/reward').then(res => {
    if (res.data.success) {
      dispatch({type: 'clearError'});
      dispatch({type: 'getRewardSettingList', res});
    } else {
      dispatch({type: 'onError', res});
    }
  });
};

export const addRewardSetting = (dispatch, values) => {
  let body;
  if (values.categoryId === REWARD_CATEGORY.CREDIT) {
    body = {
      ...values,
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSKYJsS1Y9qSS7xrdcMJDFQb6JFPEjbnvZ9XnvoTzI4xGVc8gBj&usqp=CAU',
    };
  } else if (values.categoryId === REWARD_CATEGORY.THING) {
    body = {
      ...values,
      amount: 0,
    };
  }

  Axios.post('/secure/reward/create', body).then(res => {
    if (res.data.success) {
      getRewardSettingList(dispatch);
    } else {
      alert(res.data.message);
    }
  });
};

export const editSettingDetail = (dispatch, values, id) => {
  var body = {...values, id};
  Axios.post('/secure/setting', body).then(res => {
    alert(res.data.message);
    if (res.data.success) {
      getSettingDetail(dispatch);
    }
  });
};

export const editRewardSetting = (dispatch, values, id) => {
  var body = {...values, id: id};
  Axios.post('/secure/reward/edit', body).then(res => {
    alert(res.data.message);
    if (res.data.success) {
      getRewardSettingList(dispatch);
    }
  });
};

export const deleteRewardSetting = (dispatch, id) => {
  Axios.post(`/secure/reward/delete/${id}`).then(res => {
    alert(res.data.message);
    if (res.data.success) {
      getRewardSettingList(dispatch);
    }
  });
};

export const getWheelSettingList = dispatch => {
  Axios.get('/secure/wheel').then(res => {
    if (res.data.success) {
      dispatch({type: 'clearError'});
      dispatch({type: 'getWheelSettingList', res});
    } else {
      dispatch({type: 'onError', res});
    }
  });
};

export const addWheelSetting = (dispatch, values) => {
  var body = {...values};
  Axios.post('/secure/wheel', body).then(res => {
    alert(res.data.message);
    if (res.data.success) {
      getWheelSettingList(dispatch);
    }
  });
};

export const editWheelSetting = (dispatch, values, id) => {
  Axios.post('/secure/wheel/edit', {...values, id: id}).then(res => {
    alert(res.data.message);
    if (res.data.success) {
      getWheelSettingList(dispatch);
    }
  });
};

export const editSettingWithdraw = (dispatch, payload) => {
  Axios.post('/secure/setting/withdraw', payload).then(res => {
    alert(res.data.message);
    if (res.data.success) {
      getSettingDetail(dispatch);
    }
  });
};
