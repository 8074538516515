import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  // {[k:string]:search}
  dataSource: {},
  keyword: {},
};

const reducers = {
  setDataSource: (state, action) => {
    state.dataSource[action.payload.key] = action.payload.value;
  },
  setKeyword: (state, action) => {
    state.keyword[action.payload.key] = action.payload.value;
  },

  removeKeyword: (state, action) => {
    state.keyword[action.payload.key] = '';
  },

  reset: () => initialState,
};

export const slice = createSlice({
  name: 'searchProvider',
  initialState,
  reducers,
});

export const {setKeyword, reset, removeKeyword, setDataSource} = slice.actions;

export default slice.reducer;
