import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  approveSlipRequest,
  base64VerifySlip,
  getSlipImageUrl,
  getSlipRequestList,
  manualSlipRequest,
  notfoundSlipRequest,
  rejectSlipRequest,
  slipHistoryList,
} from 'containers/Slip/apiHelper';

export const fetchSlipImageUrl = createAsyncThunk('slip/fetchSlipImageUrl', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(getSlipImageUrl(arg, {cancelToken: source.token}));

  return response.data;
});

export const fetchSlipRequestList = createAsyncThunk(
  'slip/fetchSlipRequestList',
  async (arg, {signal, rejectWithValue}) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await api(getSlipRequestList({cancelToken: source.token}));

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchApproveSlipRequest = createAsyncThunk('slip/fetchApproveSlipRequest', async (arg, thunkAPI) => {
  try {
    const response = await api(approveSlipRequest(arg));
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchNotFoundSlipRequest = createAsyncThunk('slip/fetchNotFoundSlipRequest', async (arg, thunkAPI) => {
  try {
    const response = await api(notfoundSlipRequest(arg));
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchManualSlipRequest = createAsyncThunk('slip/fetchManualSlipRequest', async (arg, thunkAPI) => {
  try {
    const response = await api(manualSlipRequest(arg));
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchRejectSlipRequest = createAsyncThunk('slip/fetchRejectSlipRequest', async (arg, thunkAPI) => {
  try {
    const response = await api(rejectSlipRequest(arg));
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchSlipHistoryList = createAsyncThunk(
  'slip/fetchSlipHistoryList',
  async (arg, {signal, rejectWithValue}) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await api(slipHistoryList({cancelToken: source.token}));

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchBase64VerifySlip = createAsyncThunk('slip/fetchBase64VerifySlip', async (arg, thunkAPI) => {
  try {
    const response = await api(base64VerifySlip(arg));
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

const extraReducers = {
  [fetchSlipImageUrl.pending]: (state, action) => {
    state.slipImageUrl[action.meta.arg.fileName] = {...action.payload, loading: true};
  },
  [fetchSlipImageUrl.fulfilled]: (state, action) => {
    state.slipImageUrl[action.meta.arg.fileName] = {...action.payload, loading: false};
  },

  [fetchSlipRequestList.pending]: (state, action) => {
    state.slipRequestList = {...action.payload, loading: true};
  },
  [fetchSlipRequestList.fulfilled]: (state, action) => {
    state.slipRequestList = {...action.payload, loading: false};
  },
  [fetchSlipRequestList.rejected]: (state, action) => {
    return action.payload;
  },

  [fetchSlipHistoryList.pending]: (state, action) => {
    state.slipHistoryList = {...action.payload, loading: true};
  },
  [fetchSlipHistoryList.fulfilled]: (state, action) => {
    state.slipHistoryList = {...action.payload, loading: false};
  },
  [fetchSlipHistoryList.rejected]: (state, action) => {
    return action.payload;
  },

  [fetchBase64VerifySlip.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchBase64VerifySlip.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
  [fetchBase64VerifySlip.rejected]: (state, action) => {
    return action.payload;
  },
};

const initialState = {
  data: {},
  slipRequestList: [],
  slipImageUrl: {},
  slipRequest: {},
  slipHistoryList: [],
};

const name = 'slip';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setSlipRequest: (state, action) => {
      state.slipRequest = action.payload;
    },
  },
  extraReducers,
});

export const {reset, setSlipRequest} = slice.actions;

export default slice.reducer;
