import React from 'react';
import {cn} from 'utils/css.util';

const DataCard = ({children, className}) => {
  return <div className={cn('', className)}>{children}</div>;
};

const Title = ({children, className}) => {
  return <h2 className={cn('', className)}>{children}</h2>;
};

const Value = ({children, className}) => {
  return <h2 className={cn('', className)}>{children}</h2>;
};

DataCard.Title = Title;
DataCard.Value = Value;

export default DataCard;
