export function balance(payload, {cancelToken}) {
  const {id} = payload;
  return {
    method: 'GET',
    endpoint: `/secure/withdraw/bank-balance/${id}`,
    cancelToken,
  };
}

export function sum(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: '/secure/report/withdraw/status',
    data: payload,
    cancelToken,
  };
}

export function withdrawRequest({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/withdraw/request',
    cancelToken,
  };
}

export function withdrawHistory({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/withdraw/history',
    cancelToken,
  };
}

export function withdrawSCB(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/scb',
    data: payload,
  };
}

export function withdrawBAY(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/bay',
    data: payload,
  };
}

export function withdrawKBANK(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/kbank',
    data: payload,
  };
}

export function withdrawKBANKCancelQrCode(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/kbank/cancel/qrcode',
    data: payload,
  };
}

export function withdrawKBANKConfirmQrCode(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/kbank/confirm/qrcode',
    data: payload,
  };
}

export function withdrawGSB(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/gsb',
    data: payload,
  };
}

export function confirm(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/confirm-transaction',
    data: payload,
  };
}

export function manual(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/confirm',
    data: payload,
  };
}

export function cancelWithRefund(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/cancel',
    data: payload,
  };
}

export function cancelNoRefund(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/withdraw/cancel-no-refund',
    data: payload,
  };
}
