import setAuthorizationToken from '../../utils/setAuthorizationToken';

const initState = {
  currentUser: null,
  loading: true,
  error: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getCurrentUser':
      return {
        ...state,
        currentUser: JSON.parse(localStorage.getItem('user')),
        loading: false,
        error: null,
      };
    case 'noCurrentUser':
      return {
        ...state,
        currentUser: null,
        loading: false,
        error: null,
      };
    case 'LoginSuccess':
      var resData = action.res.data;
      setAuthorizationToken(resData.token, resData.user);

      return {
        ...state,
        currentUser: resData.user,
        loading: false,
        error: null,
      };
    case 'LoginFail':
      var loginError = action.res.data.message;

      return {
        ...state,
        loading: false,
        error: loginError,
      };
    case 'SessionTimeout':
      setAuthorizationToken();

      return {
        ...state,
        currentUser: null,
        loading: false,
      };
    case 'Logout':
      setAuthorizationToken();

      return {
        ...state,
        currentUser: null,
        loading: false,
      };
    default:
      return state;
  }
};
export default authReducer;
