import get from 'lodash/get';
import {io} from 'socket.io-client';
import {socketEvents} from './events';

const {REACT_APP_SERVER_URL} = process.env;

let socket;

export const initialSocket = data => {
  if (socket) {
    // console.log('already set socket!');
    return;
  }
  const _token = get(data, 'token') || localStorage.getItem('token');
  const _user = get(data, 'user') || JSON.parse(localStorage.getItem('user'));
  const server = REACT_APP_SERVER_URL.substring(0, REACT_APP_SERVER_URL.length - 4);
  const lv = get(_user, 'lv');
  const SOCKET_URL = `${server}/${lv === 0 || lv === 10 ? 'admin' : ''}`;

  if (_token && _user) {
    // console.log('set socket!');

    socket = io(SOCKET_URL, {
      transports: ['websocket'],
      auth: {
        token: _token || '',
        uuid: get(_user, 'uuid', ''),
      },
      reconnectionAttempts: 3,
      reconnectionDelayMax: 10000,
      cors: {
        origin: server,
        methods: ['GET', 'POST'],
      },
    });

    socketEvents({setValue: data.setValue}, socket);

    return socket;
  }
};

export const subscribeToChannel = (channel, cb) => {
  if (!socket) {
    // console.log('notfound socket!');
    return true;
  }
  // console.log(`subscribe ${channel}`);

  socket.on(channel, msg => {
    // console.log('Websocket event received!', msg);
    return cb(null, msg);
  });
};

export const unsubscribeToChannel = (channel, cb) => {
  if (socket) {
    // console.log('unsub..');
    socket.off(channel, cb);
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket.removeAllListeners();
    socket = null;
  }
};

export default socket;
