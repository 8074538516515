import api from 'api/api';
import get from 'lodash/get';
import {bankTransfer, bankTransferLog} from 'containers/Transfer/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
  step1: {},
  result: {},
  log: {},
};

export const fetchBankTransfer = createAsyncThunk('transfer/fetchBankTransfer', async (arg, {getState}) => {
  const state = await getState();
  const payload = get(state, 'transfer.step1');
  const response = await api(bankTransfer({...payload, ...arg}));
  return response.data;
});

export const fetchBankTransferLog = createAsyncThunk('transfer/fetchBankTransferLog', async () => {
  const response = await api(bankTransferLog());
  return response.data;
});

const extraReducers = {
  [fetchBankTransfer.pending]: (state, action) => {
    state.result = {...action.payload, loading: true};
  },
  [fetchBankTransfer.fulfilled]: (state, action) => {
    state.result = {...action.payload, loading: false};
  },
  [fetchBankTransfer.rejected]: (state, action) => {
    state.result = {...action.payload, loading: false};
  },
  [fetchBankTransferLog.pending]: (state, action) => {
    state.log = {...action.payload, loading: true};
  },
  [fetchBankTransferLog.fulfilled]: (state, action) => {
    state.log = {...action.payload, loading: false};
  },
};

const name = 'transfer';

export const transferSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setStep1: (state, action) => {
      state.step1 = action.payload;
    },
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setStep1} = transferSlice.actions;

export default transferSlice.reducer;
