export default {
  CLIENT_ID: 'GIMIXO',
  firebaseConfig: {
    apiKey: 'AIzaSyC347ABrUFowKSMpSEkkfV0Z-y2f84Jyc4',
    authDomain: 'gimi-web-agent-v2.firebaseapp.com',
    projectId: 'gimi-web-agent-v2',
    storageBucket: 'gimi-web-agent-v2.appspot.com',
    messagingSenderId: '132307404856',
    appId: '1:132307404856:web:75b8ad318334a79e0afecd',
    measurementId: 'G-84XDDYNXLH',
  },
};
