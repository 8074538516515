import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import * as Yup from 'yup';

import {Button, makeStyles} from '@material-ui/core';

import {popup} from 'utils/common.util';

import {closeDialog} from 'store/uiProvider.slice';
import {
  fetchNotFoundSlipRequest,
  fetchSlipHistoryList,
  fetchSlipImageUrl,
  fetchSlipRequestList,
} from 'store/slip.slice';

import {Field, Form, Formik} from 'formik';

import NearbyDepositTransaction from 'components/Deposit/NearbyDepositTransaction';
import Spinner from 'components/shared/Spinner';
import UserInformation from 'components/User/UserInfomation';
import {CustomInputComponent} from 'components/shared/Form/FormInput';
import {SlipComparisonDetail} from 'components/Slip/SlipDetail';
import {Typography} from 'components/shared/Typography';

// eslint-disable-next-line no-undef
const {REACT_APP_MERCHANT_ID} = process.env;

const useStyles = makeStyles(() => ({
  cancelBtn: {
    background: '#C0C0C0',
    color: 'black',
  },
  notFoundBtn: {
    backgroundColor: '#ffa100',
    color: 'white',
  },
}));

const notFoundSchema = Yup.object().shape({
  username: Yup.string().min(9, 'กรุณากรอกข้อมูลรหัสให้ถูกต้อง').max(20).required('กรุณากรอกรหัส'),
});

const NotfoundSlipRequestDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const slipRequest = useSelector(state => get(state, 'slip.slipRequest', {}));
  const slipImageUrl = useSelector(state => get(state, `slip.slipImageUrl`, ''));
  const imageUrl = slipImageUrl[slipRequest.fileName]?.data;

  const initialValues = {
    username: REACT_APP_MERCHANT_ID,
  };

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  // eslint-disable-next-line no-unused-vars
  const handleNotFoundSlipRequest = async (values, actions) => {
    try {
      const response = await dispatch(fetchNotFoundSlipRequest({uuid: slipRequest.uuid, username: values.username}));

      if (response.payload.success) {
        await dispatch(fetchSlipRequestList());
        await dispatch(fetchSlipHistoryList());
        dispatch(closeDialog());
        await popup({
          icon: 'success',
          title: response?.data?.data?.message || 'อนุมัติรายการฝากด้วยสลิปสําเร็จ',
        });
      } else {
        dispatch(closeDialog());
        throw response.payload.data;
      }
    } catch (err) {
      const message = err?.data?.message || err.message;
      return popup({
        icon: 'error',
        title: message ?? 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
        timer: 0,
      });
    }
  };

  const getImageUrl = async () => {
    await dispatch(fetchSlipImageUrl({fileName: slipRequest.fileName, userId: slipRequest.userUuid}));
  };

  useEffect(() => {
    if (!imageUrl) {
      getImageUrl();
    }
  }, [slipRequest]);

  return (
    <div>
      <div className="content">
        <h1 className="text-center text-xl text-[#ffa100]">
          <p>คุณต้องการที่จะอนุมัติรายการฝากด้วยสลิปแบบไม่เจอบัญชีต้นทาง</p>
          <p>รายการนี้ใช่หรือไหม ?</p>
        </h1>

        <Formik
          validationSchema={notFoundSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => handleNotFoundSlipRequest(values, actions)}>
          {props => {
            return (
              <Form>
                <div className="py-4">
                  <div id="Slip" className="flex justify-center items-center">
                    <img src={imageUrl} alt={'slip'} className="rounded-md w-96" />
                  </div>

                  {/* <h3 className="text-red-500 text-center mt-2 mb-4">!! กรุณาตรวจสอบข้อมูลรายละเอียดก่อนลบรายการ</h3> */}

                  <div className="grid grid-cols-1 gap-3">
                    <UserInformation information={slipRequest?.user} />

                    <div className="px-4 py-6 bg-[#212121] rounded-md">
                      <Typography className="text-white text-base">
                        <span>ยูสเซอร์เนม</span>
                      </Typography>
                      <Field name="username" type="text" component={CustomInputComponent} />
                    </div>

                    <SlipComparisonDetail slipRequest={slipRequest} />

                    <NearbyDepositTransaction
                      userUuid={slipRequest?.user?.uuid}
                      amount={slipRequest?.amount}
                      transactionDate={slipRequest?.transactionDate}
                    />
                  </div>
                </div>

                <div id="aciton" className="grid grid-cols-2 gap-2">
                  <Button
                    type="reset"
                    className={classes.cancelBtn}
                    onClick={handleCancel}
                    disabled={props.isSubmitting}>
                    ปิด
                  </Button>
                  <Button type="submit" className={classes.notFoundBtn} disabled={props.isSubmitting}>
                    {props.isSubmitting ? <Spinner size={20} /> : 'ยันยันการฝาก'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default NotfoundSlipRequestDialog;
