import get from 'lodash/get';
import SimpleTable from 'components/shared/SimpleTable';
import {columnsDepositManual} from '../columns';
import {isEmpty} from 'lodash';
import {useSelector} from 'react-redux';

const Table = () => {
  const listManual = useSelector(state => get(state, 'deposit.listManual.data', []));

  return (
    <div className="flex flex-col">
      {/* <div className="mb-2 text-right"> */}
      {/*   <Button */}
      {/*     onClick={handleRecheckManualDeposit} */}
      {/*     variant="contained" */}
      {/*     size="large" */}
      {/*     style={{ */}
      {/*       backgroundColor: listManual?.loading ? '#d8d8d8' : '#CF6679', */}
      {/*       color: listManual?.loading ? '#8a8a8a' : 'white', */}
      {/*     }}> */}
      {/*     {listManual?.loading ? 'กำลังตรวจสอบ' : 'ตรวจสอบ'} */}
      {/*   </Button> */}
      {/* </div> */}
      {listManual?.loading ? null : isEmpty(listManual) ? null : (
        <div className="">
          <SimpleTable dataSource={listManual} columns={columnsDepositManual} total={listManual.length} />
        </div>
      )}
    </div>
  );
};

export default Table;
