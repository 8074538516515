// Import the functions you need from the SDKs you need
// import {getAnalytics} from 'firebase/analytics';
import configs from 'configs';
import store from 'store';
import {fetchAndActivate, getAll, getRemoteConfig} from 'firebase/remote-config';
import {initializeApp} from 'firebase/app';
import {setRemoteConfig} from 'store/global.slice';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(configs.envConfig.firebaseConfig);

const remoteConfig = getRemoteConfig();

remoteConfig.settings = {
  fetchTimeoutMillis: 60000,
  minimumFetchIntervalMillis: 60000, // process.env.NODE_ENV !== 'production' ? 60000 : 3600000,
};

remoteConfig.defaultConfig = {
  WELCOME_MSG: 'Welcome',
};

const getConfig = async () => {
  fetchAndActivate(remoteConfig).then(() => {
    const value = getAll(remoteConfig);
    store.dispatch(setRemoteConfig(format(value)));
  });
  // .catch(err => {
  //   // console.log(err);
  // });
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const format = object => {
  const config = {};
  for (const property in object) {
    const {_value} = object[property];
    // console.log(`${property}`);
    config[property] = isJson(_value) ? JSON.parse(_value) : _value;
  }
  return config;
};

export {app, remoteConfig, getConfig};
