import React from 'react';

const Level = props => {
  const {level, width, paramLevel} = props;

  if (level === 0) {
    return <img src={`/assets/images/user/500x500-01.png`} width={width} alt="user-level" />;
  }

  if (level === 1 && paramLevel === 'vip') {
    return <img src={`/assets/images/user/500x500-04.png`} width={width} alt="user-level" />;
  }

  if (level === 5) {
    return <img src={`/assets/images/user/500x500-04.png`} width={width} alt="user-level" />;
  }

  return <img src={`/assets/images/user/500x500-0${level}.png`} width={width} alt="user-level" />;
};

export default Level;
