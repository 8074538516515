import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {logs} from 'containers/Logs/apiHelper';

export const fetchLogList = createAsyncThunk('log/fetchList', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(logs(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchLogListByUserId = createAsyncThunk('log/fetchLogListByUserId', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(logs(arg, {cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchLogList.pending]: (state, action) => {
    state.logList = {...action.payload, loading: true};
  },
  [fetchLogList.fulfilled]: (state, action) => {
    state.logList = {...action.payload, loading: false};
  },
  [fetchLogList.rejected]: (state, action) => {
    return action.payload;
  },
  [fetchLogListByUserId.pending]: (state, action) => {
    state.userLogList = {...action.payload, loading: true};
  },
  [fetchLogListByUserId.fulfilled]: (state, action) => {
    state.userLogList = {...action.payload, loading: false};
  },
  [fetchLogListByUserId.rejected]: (state, action) => {
    return action.payload;
  },
};

const initialState = {
  logList: {},
  userLogList: {},
};

const name = 'logs';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
