export default {
  CLIENT_ID: 'ALFA555',
  firebaseConfig: {
    apiKey: 'AIzaSyC347ABrUFowKSMpSEkkfV0Z-y2f84Jyc4',
    authDomain: 'gimi-web-agent-v2.firebaseapp.com',
    projectId: 'gimi-web-agent-v2',
    storageBucket: 'gimi-web-agent-v2.appspot.com',
    messagingSenderId: '132307404856',
    appId: '1:132307404856:web:da4825de293461350afecd',
    measurementId: 'G-E3FMM5XB2J',
  },
};
