import {get} from 'lodash';

import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';

import {Button, makeStyles} from '@material-ui/core';

import {numberFormat} from 'utils/format-number.util';

import {closeDialog} from 'store/uiProvider.slice';
import {
  fetchWithdrawHistory,
  fetchWithdrawKBANKCancelQrCode,
  fetchWithdrawKBANKConfirmQrCode,
  fetchWithdrawRequest,
} from 'store/withdraw.slice';

export const useStyles = makeStyles(() => ({
  approveBtn: {
    background: '#00c851',
    color: 'white',
    width: '100%',
  },
  notFoundBtn: {
    background: '#ffa100',
    color: 'white',
  },
  manualBtn: {
    background: '#B23CFD',
    color: 'white',
  },
  rejectBtn: {
    backgroundColor: '#9A070B',
    color: 'white',
    width: '100%',
  },
  disableBtn: {
    background: '#696969',
    color: 'white',
  },
}));

const KbizScanQrCodeForTransfer = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const withdrawRequestData = useSelector(state => get(state, 'withdraw.scanQrCode', []));

  const withdrawHistory = get(withdrawRequestData, 'withdarw', {});

  const qrCodeBase64 = get(withdrawRequestData, 'required.qrCodeBase64', '');

  const [loading, setLoading] = useState(false);

  const handleCancelKbizTransfer = async () => {
    // Call api for change status from 10 to 3
    setLoading(true);
    try {
      await dispatch(
        fetchWithdrawKBANKCancelQrCode({
          withdrawId: withdrawRequestData?.withdrawId,
        })
      );
      await dispatch(fetchWithdrawRequest());
      await dispatch(fetchWithdrawHistory());
      dispatch(closeDialog());
    } catch (error) {
      alert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmKbizTransfer = async () => {
    setLoading(true);
    try {
      await dispatch(
        fetchWithdrawKBANKConfirmQrCode({
          withdrawId: withdrawRequestData?.withdrawId,
          bankId: withdrawRequestData?.bankid,
          withdrawHistoryId: withdrawHistory?.uuid || '',
        })
      );
      await dispatch(fetchWithdrawRequest());
      await dispatch(fetchWithdrawHistory());
      dispatch(closeDialog());
    } catch (error) {
      alert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-4">
      <h1 className="font-bold text-xl">ยืนยันการทำรายการ</h1>

      <div className="my-4">
        <div></div>
        <div className="flex justify-between items-center border-b border-gray-700 py-2">
          <h2 className="text-gray-200">จำนวนเงิน (บาท)</h2>
          <h2 className="font-bold text-gray-300">{numberFormat(withdrawRequestData?.withdarw?.amount)}</h2>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <h1 className="font-semibold">สแกน QR Code เพื่อยืนยันการทำรายการ</h1>
        <div className="bg-white w-48 rounded-lg mt-4">
          <img src={qrCodeBase64} alt="qr-code-to-transfer" className="w-full" />
        </div>
      </div>

      <div className="text-center text-red-400 py-4">สแกนยืนยันภายใน 3 นาที</div>

      <div className="py-4">
        {/* <Button className={classes.rejectBtn} onClick={handleCancelKbizTransfer} disabled={loading}> */}
        {/*   ยกเลิก */}
        {/* </Button> */}
        <Button className={classes.approveBtn} onClick={handleConfirmKbizTransfer} disabled={loading}>
          ยืนยัน
        </Button>
      </div>
    </div>
  );
};

export default KbizScanQrCodeForTransfer;
