import Container from 'components/shared/Container';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {moment} from 'utils/date.util';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const now = moment();

const News = () => {
  const remoteConfig = useSelector(state => state.global.remoteConfig);
  const [isInDeadLineDates, setIsInDeadLineDates] = useState(false);

  const SHUTDOWN = get(remoteConfig, 'MODE.SHUTDOWN');
  const TEXT = get(remoteConfig, `NEWS.${SHUTDOWN ? 'CLOSED' : 'WARNING'}`);
  const DEADLINE_DATES = get(remoteConfig, 'NEWS.DEADLINE_DATE');

  const parseText = (query, text) => {
    const regex = new RegExp(query, 'g');
    const nextMonth = moment().add(1, 'M');
    let displayMonth = nextMonth.format('MMMM');
    if ([1, 2, 3, 4, 5].includes(now.get('date'))) {
      displayMonth = now.format('MMMM');
    }
    return text?.replace(regex, displayMonth);
  };

  useEffect(() => {
    if (DEADLINE_DATES) {
      setIsInDeadLineDates(JSON.parse(DEADLINE_DATES)?.includes(now.get('date')));
    }
  }, [remoteConfig]);

  // console.log(DEADLINE_DATES);
  if (!isEmpty(TEXT) && isInDeadLineDates) {
    return (
      <div className="pt-2 bg-black">
        <Container>
          <p className="border rounded-md p-2 text-center text-red-400">{parseText('{MONTH}', TEXT)}</p>
        </Container>
      </div>
    );
  }

  return null;
};

export default News;
