import api from 'api/api';
import axios from 'axios';
import {activeBanks} from 'containers/Transfer/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchBankActive = createAsyncThunk('global/fetchBankActive', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(activeBanks({cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchBankActive.pending]: (state, action) => {
    state.bank = {...action.payload, loading: true};
  },
  [fetchBankActive.fulfilled]: (state, action) => {
    state.bank = {...action.payload, loading: false};
  },
};

const initialState = {
  error: {},
  remoteConfig: {},
  bank: {},
};

const name = 'global';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setRemoteConfig: (state, action) => {
      state.remoteConfig = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setRemoteConfig} = slice.actions;

export default slice.reducer;
