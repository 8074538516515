import {Button, withStyles} from '@material-ui/core';

const DefaultButton = withStyles(() => ({
  root: {
    fontSize: 16,
    color: '#212121',
    background: '#ebedf3',
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    cursor: 'pointer',
    boxShadow: 'none',
    textTransform: 'none',
    lineHeight: 1.5,
    '&:hover': {
      color: '#fff',
      background: '#555555',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}))(Button);

export {DefaultButton};
