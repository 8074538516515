export function games(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/game/list`,
    params: payload,
    cancelToken,
  };
}

export function gameById(payload, {cancelToken}) {
  const {gameId} = payload;
  return {
    method: 'GET',
    endpoint: `/secure/game/${gameId}`,
    params: payload,
    cancelToken,
  };
}

export function createGame(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/game/create/`,
    data: payload,
  };
}

export function editGame(payload) {
  const {id} = payload;
  return {
    method: 'PATCH',
    endpoint: `/secure/game/edit/${id}`,
    data: payload,
  };
}

export function deleteGame(payload) {
  const {id} = payload;
  return {
    method: 'DELETE',
    endpoint: `/secure/game/delete/${id}`,
  };
}

export function createGameValue(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/gameValue/create/`,
    data: payload,
  };
}

export function gameValues(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/gameValue/list`,
    params: payload,
    cancelToken,
  };
}

export function editGameValue(payload) {
  const {id} = payload;
  return {
    method: 'PATCH',
    endpoint: `/secure/gameValue/edit/${id}`,
    data: payload,
  };
}
