import moment from 'moment';
import {debounce, get, isEmpty} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Button, makeStyles} from '@material-ui/core';

import {Field, Form, Formik, useFormikContext} from 'formik';
import * as Yup from 'yup';

import {BANK_TYPE} from 'constants/bank';
import {popup} from 'utils/common.util';

import {closeDialog} from 'store/uiProvider.slice';
import {fetchBankActive} from 'store/global.slice';
import {fetchDepositListManual, setManualList} from 'store/deposit.slice';
import {fetchManualSlipRequest, fetchSlipHistoryList, fetchSlipImageUrl, fetchSlipRequestList} from 'store/slip.slice';

import Spinner from 'components/shared/Spinner';
import Table from 'components/Deposit/Manual/Table';
import UserInformation from 'components/User/UserInfomation';
import {CustomInputComponent} from 'components/shared/Form/FormInput';
import {SelectBlack} from 'components/shared/Select/SelectBlack';
import {Separator} from 'components/shared/Separator';
import {Typography} from 'components/shared/Typography';

// eslint-disable-next-line no-undef
const {REACT_APP_MERCHANT_ID} = process.env;

const useStyles = makeStyles(theme => ({
  cancelBtn: {
    background: '#C0C0C0',
    color: 'black',
    width: '100%',
  },
  confirmBtn: {
    backgroundColor: '#B23CFD',
    color: 'white',
    width: '100%',
  },
  textFieldColor: {
    color: '#4285f4',
    backgroundColor: theme.palette.background.grey1,
  },
  divider: {
    backgroundColor: theme.palette.background.dark,
  },
}));

const manualSlipRequestSchema = Yup.object().shape({
  amount: Yup.number()
    .min(1, 'กรุณากรอกจำนวนเงินให้ถูกต้อง')
    .max(100000, 'ไม่เกิน 100,000')
    .required('กรุณากรอกจำนวนเงิน'),
  username: Yup.string().min(9, 'กรุณากรอกข้อมูลรหัสให้ถูกต้อง').max(20).required('กรุณากรอกรหัส'),
  day: Yup.number().min(1, 'กรุณากรอกวันที่ให้ถูกต้อง').required('กรุณากรอกวันที่'),
  month: Yup.number().min(1, 'กรุณากรอกเดือนให้ถูกต้อง').required('กรุณากรอกเดือน'),
  year: Yup.number().min(1, 'กรุณากรอกปีให้ถูกต้อง').required('กรุณากรอกปี'),
  hour: Yup.number()
    .min(0, 'กรุณากรอกชั่วโมงให้ถูกต้อง')
    .max(23, 'กรุณากรอกชั่วโมงให้ถูกต้อง')
    .required('กรุณากรอกชั่วโมง'),
  minute: Yup.number().min(0, 'กรุณากรอกนาทีให้ถูกต้อง').required('กรุณากรอกนาที'),
  note: Yup.string().max(255).required('กรุณากรอกหมายเหตุ'),
  bankId: Yup.number().required('กรุณาเลือกธนาคาร'),
});

const AutoCheckDepositList = props => {
  const {onCheckViaApi} = props;
  const dispatch = useDispatch();
  const {values, setErrors} = useFormikContext();
  const manuals = useSelector(state => get(state, 'deposit.listManual.data', []));
  const deposits = useSelector(state => get(state, 'deposit.list.data.rows', []));

  const verify = useCallback(
    debounce(data => {
      const allFieldHaveValue = Object.values(data).every(item => item !== '');
      if (!allFieldHaveValue) return;
      const {username, amount, year, month, day, hour, minute} = data;
      if (allFieldHaveValue) {
        const transactionString = `${year}-${month}-${day} ${hour}:${minute}`;

        const nextDeposit = deposits.filter(deposit => {
          return moment(deposit.transactionDate, 'YYYY-MM-DD HH:mm').isSame(
            moment(transactionString, 'YYYY-MM-DD HH:mm') &&
              parseFloat(amount) === deposit?.amount &&
              username &&
              deposit?.user?.user_pass?.username
          );
        });
        if (!isEmpty(nextDeposit)) {
          dispatch(setManualList(nextDeposit));
          return;
        }
        const transactionDate = Date.parse(transactionString);
        const transactionUnixTimestamp = transactionDate / 1000;

        onCheckViaApi && onCheckViaApi(transactionUnixTimestamp, username, amount);
      }
    }, 400),
    []
  );

  const setNote = useCallback(() => {
    setErrors({note: 'เติมมือรายการนี้แล้วครับ'});
  }, []);

  useEffect(() => {
    dispatch(fetchBankActive());
  }, []);

  useEffect(() => {
    if (!isEmpty(manuals)) {
      setNote();
    }
  }, [manuals]);

  useEffect(() => {
    if (values.note.length > 1) {
      verify(values);
    }
  }, [values]);
  return null;
};

const ManualSlipRequestDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const banks = useSelector(state => get(state, 'global.bank.data', []));

  const slipRequest = useSelector(state => get(state, 'slip.slipRequest', {}));
  const slipImageUrl = useSelector(state => get(state, `slip.slipImageUrl`, ''));
  const imageUrl = slipImageUrl[slipRequest.fileName]?.data;

  const [bankOptions, setBankOptions] = useState([]);

  const dateNow = new Date();

  const initialValues = {
    amount: '',
    // username: slipRequest?.user?.user_pass?.username,
    username: REACT_APP_MERCHANT_ID,
    day: dateNow.getDate(),
    month: dateNow.getMonth() + 1,
    year: dateNow.getFullYear(),
    hour: dateNow.getHours(),
    minute: dateNow.getMinutes(),
    note: '',
    bankId: 0,
  };

  const isValidDate = date => new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const handleManualSlipRequest = async (values, actions) => {
    try {
      const transactionDate = Date.parse(
        `${values.month}/${values.day}/${values.year} ${values.hour}:${values.minute}`
      );

      if (!isValidDate(transactionDate)) {
        alert('รูปแบบวันที่ไม่ถูกต้องครับ');
        actions.setSubmitting(false);
        return;
      }

      const tomorrowManual = transactionDate > Date.now();
      const yesterdayCanManual = moment().diff(moment(transactionDate), 'hour') > 24;

      if (tomorrowManual || yesterdayCanManual) {
        alert('ไม่สามารถเติมเงินได้');
        actions.setSubmitting(false);
        return;
      }

      const transactionString = `${values.year}-${values.month}-${values.day} ${values.hour}:${values.minute}`;

      const response = await dispatch(
        fetchManualSlipRequest({
          uuid: slipRequest.uuid,
          transactionDate: transactionString,
          amount: values.amount,
          note: values.note,
          bankId: values.bankId,
        })
      );

      if (response.payload.success) {
        await dispatch(fetchSlipRequestList());
        await dispatch(fetchSlipHistoryList());
        dispatch(closeDialog());
        await popup({
          icon: 'success',
          title: response?.data?.data?.message || 'เติมมือจากสลิปสำเร็จ',
        });
      } else {
        dispatch(closeDialog());
        throw response.payload.data;
      }
    } catch (err) {
      const message = err?.data?.message || err.message;
      return popup({
        icon: 'error',
        title: message ?? 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
        timer: 0,
      });
    }
  };

  const getImageUrl = async () => {
    await dispatch(fetchSlipImageUrl({fileName: slipRequest.fileName, userId: slipRequest.userUuid}));
  };

  const handleCheckViaApi = (unixTime, username, amount) => {
    dispatch(fetchDepositListManual({transactionUnixTimestamp: unixTime, username, amount}));
  };

  useEffect(() => {
    if (!imageUrl) {
      getImageUrl();
    }
  }, [slipRequest]);

  useEffect(() => {
    if (Array.isArray(banks) && banks.length) {
      const options = () =>
        banks
          .filter(bank => bank.type === BANK_TYPE.DEPOSIT)
          .map(item => ({value: item.id, label: `${item.bankName} ${item?.bank} ${item.bankId.substring(6, 10)}`}));
      setBankOptions(options());
    }
  }, [banks]);

  return (
    <div>
      <div className="content">
        <h1 className="text-center text-xl text-[#B23CFD]">
          <p>คุณต้องการที่จะเติมมือจากรายการฝากด้วยสลิป</p>
          <p>รายการนี้ใช่หรือไหม ?</p>
        </h1>

        <div className="py-4">
          <div id="Slip" className="flex justify-center items-center">
            <img src={imageUrl} alt={'slip'} className="rounded-md w-96" />
          </div>

          <h3 className="text-red-500 text-center mt-2 mb-4">!! กรุณาตรวจสอบข้อมูลรายละเอียดก่อนเติมมือ</h3>

          <div className="grid grid-cols-1 gap-3">
            <UserInformation information={slipRequest?.user} />

            <div>
              <Typography className="text-yellow-500">กรอกข้อมูลจากสลิปเพื่อเติมมือ</Typography>
              <Separator />
              <Formik
                validationSchema={manualSlipRequestSchema}
                initialValues={initialValues}
                onSubmit={(values, actions) => handleManualSlipRequest(values, actions)}>
                {props => {
                  return (
                    <Form>
                      <div className="flex flex-col w-full space-y-4">
                        <div className="flex flex-col w-full space-y-4 px-4 py-6 bg-[#212121]">
                          <div>จำนวนเงิน ( บาท )</div>
                          <Field name="amount" type="number" autoFocus component={CustomInputComponent} />
                          <div>รหัส</div>
                          <Field name="username" type="text" component={CustomInputComponent} />
                          <div style={{color: '#fb3'}} className="text-lg">
                            ***กรอกวันเวลาตามสลิป
                          </div>
                          <div className="grid grid-cols-3 gap-4">
                            <div>
                              <div>วัน</div>
                              <Field name="day" type="number" component={CustomInputComponent} />
                            </div>

                            <div>
                              <div>เดือน</div>
                              <Field name="month" type="number" component={CustomInputComponent} />
                            </div>

                            <div>
                              <div>ปี</div>
                              <Field name="year" type="number" component={CustomInputComponent} />
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <div>ชั่วโมง</div>
                              <Field name="hour" type="number" component={CustomInputComponent} />
                            </div>

                            <div>
                              <div>นาที</div>
                              <Field name="minute" type="number" component={CustomInputComponent} />
                            </div>
                          </div>

                          <Table />

                          <div>ธนาคาร</div>
                          <SelectBlack
                            isSearchable
                            id="bankId"
                            name="bankId"
                            options={bankOptions}
                            onChange={e => {
                              props.setFieldValue('bankId', e.value);
                            }}
                            helperText={props.errors.bankId}
                          />
                          {props.errors.bankId && <div className="text-red-500 ml-3">{props.errors.bankId}</div>}

                          <div>หมายเหตุ</div>
                          <Field name="note" type="text" component={CustomInputComponent} />
                        </div>

                        <div className="grid grid-cols-2 gap-3">
                          <Button className={classes.cancelBtn} onClick={handleCancel} disabled={props.isSubmitting}>
                            ปิด
                          </Button>
                          <Button type="submit" className={classes.confirmBtn} disabled={props.isSubmitting}>
                            {props.isSubmitting ? <Spinner size={20} /> : 'ยันยันเติมมือ'}
                          </Button>
                        </div>
                      </div>
                      <AutoCheckDepositList onCheckViaApi={handleCheckViaApi} />
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualSlipRequestDialog;
