import clsx from 'clsx';
import Logo from '../../components/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import {AppBar, makeStyles, Toolbar} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.grey1,
  },
  toolbar: {
    height: 64,
  },
  textColor: {
    color: 'white',
  },
}));

const TopBar = ({className, ...rest}) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
