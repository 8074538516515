export function activeBanks({cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/bank/active`,
    cancelToken,
  };
}

export function bankTransfer(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/bank/transfer`,
    data: payload,
  };
}

export function bankTransferLog(payload) {
  return {
    method: 'GET',
    endpoint: `/secure/bank/transfer/logs`,
    data: payload,
  };
}
