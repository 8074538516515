import {cn} from 'utils/css.util';

/**
 * Separator
 * @param {object} props
 * @param {string} props.orientation 'horizontal' or 'vertical'
 * @returns {jsx}
 */
export const Separator = ({orientation = 'horizontal', className}) => {
  return (
    <div
      className={cn(
        ' bg-gray-500 ',
        orientation === 'horizontal' ? 'h-[1px] w-full my-2' : 'w-[1px] h-full mx-2',
        className
      )}
    />
  );
};
