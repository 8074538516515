export function settingOperational({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/setting/operational',
    cancelToken,
  };
}

export function editSettingOperational(payload) {
  return {
    method: 'PATCH',
    endpoint: '/setting/secret/operational',
    data: payload,
  };
}
