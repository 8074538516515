import {useEffect, useState} from 'react';

function getStorageValue(key, defaultValue) {
  try {
    // Get from local storage by key
    const item = localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    // If error also return initialValue
    return defaultValue;
  }
}

/** https://blog.logrocket.com/using-localstorage-react-hooks */
export const useLocalStorage = (key, defaultValue) => {
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }

    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
};
