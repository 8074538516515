import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {rewardCredit, rewardThing, rewardWheel} from 'containers/UserDetail/apiHelper';

const initialState = {
  credit: {},
  thing: {},
  wheel: {},
};

export const fetchRewardCredit = createAsyncThunk('userDetail/fetchRewardCredit', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(rewardCredit(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchRewardThing = createAsyncThunk('userDetail/fetchRewardThing', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(rewardThing(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchRewardWheel = createAsyncThunk('userDetail/fetchRewardWheel', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(rewardWheel(arg, {cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchRewardCredit.pending]: (state, action) => {
    state.credit = {...action.payload, loading: true};
  },
  [fetchRewardCredit.fulfilled]: (state, action) => {
    state.credit = {...action.payload, loading: false};
  },
  [fetchRewardThing.pending]: (state, action) => {
    state.thing = {...action.payload, loading: true};
  },
  [fetchRewardThing.fulfilled]: (state, action) => {
    state.thing = {...action.payload, loading: false};
  },
  [fetchRewardWheel.pending]: (state, action) => {
    state.wheel = {...action.payload, loading: true};
  },
  [fetchRewardWheel.fulfilled]: (state, action) => {
    state.wheel = {...action.payload, loading: false};
  },
};

const name = 'userDetail';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = slice.actions;

export default slice.reducer;
