import api from 'api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {userRegister} from 'containers/ManualRegister/apiHelper';

export const fetchUserRegister = createAsyncThunk('register/fetchUserRegister', async arg => {
  const response = await api(userRegister(arg));
  return response.data;
});

const extraReducers = {
  [fetchUserRegister.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchUserRegister.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
};

const initialState = {
  data: {},
};

const name = 'register';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = slice.actions;

export default slice.reducer;
