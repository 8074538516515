import get from 'lodash/get';
import React from 'react';
import {DEPOSIT_STATUS} from 'constants/index';
import {fetchDepositPull} from 'store/deposit.slice';
import {numberFormat} from 'utils/format-number.util';
import {PrimaryButton} from 'components/shared/Button';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';

const {FAIL, TIMEOUT, NOT_FOUND} = DEPOSIT_STATUS;

const Pull = props => {
  const {deposit, done} = props;
  const {id, status, amount} = deposit;
  const username = get(deposit, 'user.user_pass.username', '');
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const {isDeposit} = currentUser;

  const handleClick = () => {
    if (window.confirm(`คุณต้องการดึงเครดิตของ ${username} จำนวน ${numberFormat(amount)} บาท กลับหรือไม่`)) {
      dispatch(fetchDepositPull({id}))
        .then(unwrapResult)
        .then(res => {
          if (!res.success) {
            alert(res.message);
            return;
          }
          done();
          alert(res.message);
        });
    }
  };

  if (!isDeposit || [FAIL, TIMEOUT, NOT_FOUND].includes(status)) {
    return null;
  }

  return (
    <PrimaryButton size="small" variant="contained" onClick={handleClick}>
      ดึงเครดิต
    </PrimaryButton>
  );
};

export default Pull;
