import {useState} from 'react';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Dragger from 'components/shared/Upload/Dragger';

const UploadDragFile2 = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const Upload = props => {
    const {multiple, callbackRemoveFile, handleReset, disabled} = props;

    const handleChangeFile = files => {
      if (multiple) {
        setSelectedFiles([...selectedFiles, ...files]);
      } else {
        setSelectedFiles([...files]);
      }
    };

    return selectedFiles && selectedFiles.length > 0 ? (
      <div>
        {selectedFiles?.map((file, index) => (
          <div key={file.name + index} className="relative">
            <img
              className="w-full h-full"
              src={file.base64 ? file.base64 : URL.createObjectURL(file)}
              alt={file.name}
            />
            {disabled ? null : (
              <div className="absolute right-0 top-0 mr-2 mt-2">
                <button
                  className="w-8 h-8 border border-red-700 bg-red-500 hover:bg-red-700  rounded-full "
                  onClick={async () => {
                    if (callbackRemoveFile) {
                      callbackRemoveFile(file, index);
                    }

                    if (handleReset) {
                      handleReset();
                    }

                    handleRemoveFile(index);
                  }}>
                  X
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    ) : (
      <Dragger multiple={multiple || false} onChange={handleChangeFile}>
        <div className="flex flex-col w-full justify-center items-center border border-dashed py-8 border-white rounded-md">
          <CloudUploadIcon />
          <p>วางไฟล์ของคุณที่นี่หรือคลิกเพื่อเลือกไฟล์</p>
        </div>
      </Dragger>
    );
  };

  return {Upload, selectedFiles, setSelectedFiles};
};

export default UploadDragFile2;
