// arg = {setValue}
export const socketEvents = (arg, socket) => {
  socket.on('connect', () => {
    // console.log(socket.id);
  });

  socket.on('disconnect', () => {
    // console.log(socket.id, socket.connected); // undefined false
  });

  socket.on('connect_error', () => {
    // console.log(err.message); // prints the message associated with the error
  });
};
