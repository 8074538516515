import styled from 'styled-components';
import {TextField} from '@material-ui/core';

const StyledTextField = styled(TextField)`
  label.focused {
    color: white;
  }
  .MuiInputBase-root {
    color: #4285f4;
    background-color: #121212;
  }
  &:disabled {
    cursor: not-allowed;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #231e23;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: #39a6a3;
    }
  }
`;

// const Input = withStyles(theme => ({
//   root: {
//     '& label': {
//       fontSize: 16,
//       fontWeight: 'bold',
//       color: '#fff',
//     },
//     '& label.Mui-focused': {
//       color: '#fff',
//     },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: '#fff',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& input': {
//         // padding: '8px 14px',
//         fontSize: 16,
//         color: '#4285f4',
//       },
//       '& fieldset': {
//         borderRadius: '10px',
//         borderWidth: '1px',
//         borderColor: theme.palette.background.tableLine,
//       },
//       '&:hover fieldset': {
//         borderColor: '#fff',
//       },
//       '&.Mui-focused fieldset': {
//         borderWidth: '1px',
//         borderColor: '#fff',
//       },
//     },
//     '& .MuiFormHelperText-root': {
//       fontSize: 16,
//     },
//   },
// }))(TextField);

const CustomInput = props => {
  return (
    <StyledTextField type="text" size="small" margin="normal" variant="outlined" className="rounded-md" {...props} />
  );
};

export default CustomInput;
