import Numeral from 'numeral';

export const numberFormat = (val, format = '0,0.00') => {
  return Numeral(val).format(format);
};

export const intFormat = val => {
  return Numeral(val).format('0,0');
};

export const toLocaleString = value => {
  return parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

export const decimalFomat = val => {
  return Numeral(val).format('0,0[.]00');
};
