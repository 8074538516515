import api from 'api/api';
import axios from 'axios';

import {
  createAnnouncement,
  deleteAnnouncement,
  getAnnouncementById,
  listAnnouncement,
  updateAnnouncement,
} from 'containers/Setting/Announcement/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchListAnnouncement = createAsyncThunk('announcement/fetchListAnnouncement', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(listAnnouncement(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchAnnouncementById = createAsyncThunk('announcement/fetchAnnouncementById', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(getAnnouncementById(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchCreateAnnouncement = createAsyncThunk('announcement/fetchCreateAnnouncement', async arg => {
  const response = await api(createAnnouncement(arg));
  return response.data;
});

export const fetchUpdateAnnouncement = createAsyncThunk('announcement/fetchUpdateAnnouncement', async arg => {
  const {id} = arg;
  const response = await api(updateAnnouncement(id, arg));
  return response.data;
});

export const fetchDeleteAnnouncement = createAsyncThunk('announcement/fetchDeleteAnnouncement', async arg => {
  const {id} = arg;
  const response = await api(deleteAnnouncement(id));
  return response.data;
});

const extraReducers = {
  [fetchListAnnouncement.pending]: (state, action) => {
    state.listAnnouncement = {...action.payload, loading: true};
  },
  [fetchListAnnouncement.fulfilled]: (state, action) => {
    state.listAnnouncement = {...action.payload, loading: false};
  },
  [fetchAnnouncementById.pending]: (state, action) => {
    state.announcement = {...action.payload, loading: true};
  },
  [fetchAnnouncementById.fulfilled]: (state, action) => {
    state.announcement = {...action.payload, loading: false};
  },
};

const initialState = {
  listAnnouncement: {},
  announcement: {},
};

const name = 'announcement';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
