import axios from 'axios';
import config from '../configs';
import pjson from '../../package.json';
import setAuthorizationToken from 'utils/setAuthorizationToken';
import store from 'store/index';
import {disconnectSocket} from 'contexts/Socket';
import {osName} from 'react-device-detect';

const baseURL = config.actionUrl;
const osMap = {
  Android: 'AND',
  iOS: 'IOS',
  DEFAULT: 'DSK',
};

const defaultOptions = {
  method: 'GET',
  baseURL,
  endpoint: '',
  data: {},
  params: {},
  timeout: 60000,
  // responseType: 'json',
  headers: {
    // Accept: 'application/json',
    // 'Content-Type': 'application/json',
    platform: osMap[osName] || osMap['DEFAULT'],
    channel: 'web',
    webversion: pjson.version,
  },
};

function addDefaultApiConfig(apiOptions) {
  const endpoint = apiOptions.endpoint || defaultOptions.endpoint;

  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    ...defaultOptions.headers,
    ...apiOptions.headers,
  };

  return {
    ...defaultOptions,
    ...apiOptions,
    headers,
    url: endpoint,
  };
}

axios.interceptors.response.use(
  response => response,
  error => {
    if ([401, 403].includes(error?.response?.status)) {
      disconnectSocket();
      setAuthorizationToken();
      store.dispatch({type: 'Logout'});
      window.location.replace('/login');
    }

    return Promise.reject(error?.response);
  }
);

const api = apiOptions => {
  const apiConfig = addDefaultApiConfig(apiOptions);
  // console.log(apiConfig);
  return axios(apiConfig);
};

export default api;
