import api from 'api/api';
import axios from 'axios';
import {afWithdrawSummary, cashLimitBalance, rewardSummary} from 'containers/Dashboard/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchAFWithdrawSummary = createAsyncThunk('dashboard2/fetchAFWithdrawSummary', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(afWithdrawSummary(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchRewardSummary = createAsyncThunk('dashboard2/fetchRewardSummary', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(rewardSummary(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchCashLimitBalance = createAsyncThunk('dashboard2/fetchCashLimitBalance', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(cashLimitBalance({cancelToken: source.token}));
  return response.data;
});

const initialState = {
  af: {},
  wl: {},
  reward: {},
  cashBalance: {},
};

const name = 'dashboard2';

const extraReducers = {
  [fetchAFWithdrawSummary.pending]: (state, action) => {
    state[action.meta.arg.type] = {
      ...action.payload,
      loading: true,
    };
  },
  [fetchAFWithdrawSummary.fulfilled]: (state, action) => {
    state[action.meta.arg.type] = {
      ...action.payload,
      loading: false,
    };
  },
  [fetchRewardSummary.pending]: (state, action) => {
    state.reward = {
      ...action.payload,
      loading: true,
    };
  },
  [fetchRewardSummary.fulfilled]: (state, action) => {
    state.reward = {
      ...action.payload,
      loading: false,
    };
  },
  [fetchCashLimitBalance.pending]: (state, action) => {
    state.cashBalance = {
      ...action.payload,
      loading: true,
    };
  },
  [fetchCashLimitBalance.fulfilled]: (state, action) => {
    state.cashBalance = {
      ...action.payload,
      loading: false,
    };
  },
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setRemoteConfig: (state, action) => {
      state.remoteConfig = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = slice.actions;

export default slice.reducer;
