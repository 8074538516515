/** Pull credit from deposit */
export function pull(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/deposit/pull`,
    data: payload,
  };
}

/** Manual pull credit */
export function pullManualCredit(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/credit/pull`,
    data: payload,
  };
}

/** Manual add credit */
export function addManualCredit(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/deposit/addmanual`,
    data: payload,
  };
}

export function list(payload, {cancelToken}) {
  const {page, limit, tab = 'success'} = payload;
  return {
    method: 'GET',
    endpoint: `/secure/deposit/list?tab=${tab}&page=${page}&limit=${limit}`,
    cancelToken,
  };
}

export function search(payload) {
  const {q, page, limit, status, date} = payload;
  return {
    method: 'GET',
    // eslint-disable-next-line max-len
    endpoint: `/secure/deposit/search?q=${q}&status=${status}&date=${date}&page=${page}&limit=${limit}&order_by=createdAt&order_direction=desc`,
  };
}

export function manual(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/deposit/addmanual',
    data: payload,
  };
}

export function notfound(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/deposit/notfound',
    data: payload,
  };
}

export function fail(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/deposit',
    data: payload,
  };
}

export function timeout(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/deposit/timeout',
    data: payload,
  };
}

/** Add or Minus point */
export function updatePoint(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/credit/point/update',
    data: payload,
  };
}

/** List Manual */
export function listDepositManual(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/deposit/list/manual`,
    params: payload,
    cancelToken,
  };
}

export function nearbyDepositTransactionByUserUuid(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/deposit/v2/nearby/transaction/by/user/${payload.userUuid}`,
    params: {
      transactionDate: payload.transactionDate,
      amount: payload.amount,
    },
    cancelToken,
  };
}
