import get from 'lodash/get';
import NativeSelect from '@material-ui/core/NativeSelect';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core';
import {useLocalStorage} from 'hooks/useLocalStorage';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: 'solid',
      padding: 8,
    },
  },
  row: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

const Row = props => {
  const {record, columns, onConfirm} = props;
  const classes = useStyles();

  return (
    <TableRow hover key={'id'} className={classes.row}>
      {columns.map(column => {
        const value = get(record, column.id);

        return (
          <TableCell key={column.id} align={column.align || 'center'} width={column?.width}>
            {column.format
              ? column.id === 'action'
                ? column.format(value, record, onConfirm)
                : column.format(value, record)
              : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const SimpleTable = props => {
  const {id, dataSource, total, columns, keyword, onPaginateChange, onConfirm} = props;
  const classes = useStyles();
  const [pageSize, setPageSize] = useLocalStorage(`pageSize${id ?? ''}`, '10');

  const [page, setPage] = useState(1); // pageNum
  const [limit, setLimit] = useState(pageSize); // perPage, pageSize

  const handleChangeRowPerPage = event => {
    const value = event.target.value;
    // console.log('limit changed', value);
    setPage(1);
    setLimit(value);
    setPageSize(value);
    onPaginateChange && onPaginateChange({page: 1, limit: value});
  };

  const handlePageChange = (event, value) => {
    // console.log('page changed', value);
    setPage(value);
    onPaginateChange && onPaginateChange({page: value});
  };

  useEffect(() => {
    if (keyword) {
      setPage(1);
    }
  }, [keyword]);

  const count = Math.ceil(total / limit) === 0 ? 1 : Math.ceil(total / limit);

  const exceedLimit = Array.isArray(dataSource) && dataSource.length > limit;
  // console.log('in table', dataSource, total, count);
  return (
    <div>
      <div className="pt-2">
        <TableContainer component={Paper} className={classes.table}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align || 'center'} style={{minWidth: column.width}}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource?.slice((page - 1) * limit, page * limit)?.map(row => (
                <Row key={row?.uuid || row?.id} record={row} columns={columns} onConfirm={onConfirm} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {exceedLimit && (
        <div className="flex justify-between items-center pt-2">
          <NativeSelect
            value={limit}
            onChange={handleChangeRowPerPage}
            inputProps={{
              name: 'limit',
              id: 'limit',
            }}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </NativeSelect>
          <div className="flex items-center">
            <Pagination
              count={count}
              page={page}
              boundaryCount={2}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SimpleTable;
