import SocketContext from './socket.context';
import {EVENT} from 'constants/socket';
import {initialSocket, subscribeToChannel, unsubscribeToChannel} from './index';
import {isEmpty} from 'lodash';
// import {toast} from 'react-toastify';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

// const audio = new Audio('/static/sound/note.mp3');
// audio.load();

const SocketProvider = props => {
  const {children} = props;
  // const navigate = useNavigate();

  const [value, setValue] = useState({deposit: {}, withdraw_notify: {}});
  const currentUser = useSelector(state => state.auth.currentUser);
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));

  const isLoggedIn = !isEmpty(currentUser);

  useEffect(() => {
    // console.log('user and token changed..', currentUser);

    initialSocket({token, user, setValue});

    // const withdrawNotifyListener = (err, payload) => {
    //   if (err) return;
    //   const username = get(payload, 'user.user_pass.username');
    //   const amount = get(payload, 'amount');
    //   toast(`${username} แจ้งถอน ${amount}`, {onClick: () => navigate('/admin/withdraw')});
    //   // audio.play();
    //   setValue(prevState => ({...prevState, withdraw_notify: payload}));
    // };

    const depositListener = (err, payload) => {
      if (err) return;

      setValue(prevState => ({...prevState, deposit: payload}));
    };

    if (isLoggedIn) {
      // subscribeToChannel(EVENT.WITHDRAW_NOTIFY, withdrawNotifyListener);
      subscribeToChannel(EVENT.DEPOSIT, depositListener);
    }

    return () => {
      // unsubscribeToChannel(EVENT.WITHDRAW_NOTIFY, withdrawNotifyListener);
      unsubscribeToChannel(EVENT.DEPOSIT, depositListener);
    };
  }, [currentUser]);

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
