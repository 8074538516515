export function logs(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/secure/logs`,
    params: {
      limit: payload?.limit,
      page: payload?.page,
    },
    data: {
      type: payload?.type,
      admin: payload?.admin,
      user: payload?.user,
      action: payload?.action,
    },
    cancelToken,
  };
}
