import React from 'react';

const Logo = () => {
  if (process.env.NODE_ENV !== 'development') {
    return <img alt={process.env.REACT_APP_CLIENT_ID} src="/static/images/logo.png" className="h-10" />;
  }

  return <img alt={process.env.REACT_APP_CLIENT_ID} src="/static/images/logo-dev.png" className="h-10" />;
};

export default Logo;
