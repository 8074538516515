import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {Button, makeStyles} from '@material-ui/core';

import {popup} from 'utils/common.util';

import {closeDialog} from 'store/uiProvider.slice';
import {fetchApproveSlipRequest, fetchSlipHistoryList, fetchSlipImageUrl, fetchSlipRequestList} from 'store/slip.slice';

import NearbyDepositTransaction from 'components/Deposit/NearbyDepositTransaction';
import Spinner from 'components/shared/Spinner';

import UserInformation from 'components/User/UserInfomation';
import {SlipComparisonDetail} from 'components/Slip/SlipDetail';

const useStyles = makeStyles(() => ({
  cancelBtn: {
    background: '#C0C0C0',
    color: 'black',
  },
  confirmBtn: {
    backgroundColor: '#20C55E',
    color: 'white',
  },
}));

const ApproveSlipRequestDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const slipRequest = useSelector(state => get(state, 'slip.slipRequest', {}));
  const slipImageUrl = useSelector(state => get(state, `slip.slipImageUrl`, ''));
  const imageUrl = slipImageUrl[slipRequest.fileName]?.data;

  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const handleApproveSlipRequest = async () => {
    setLoading(true);
    try {
      const response = await dispatch(fetchApproveSlipRequest({uuid: slipRequest.uuid}));

      if (response.payload.success) {
        await dispatch(fetchSlipRequestList());
        await dispatch(fetchSlipHistoryList());
        dispatch(closeDialog());
        await popup({
          icon: 'success',
          title: response?.data?.data?.message || 'อนุมัติรายการฝากด้วยสลิปสําเร็จ',
        });
      } else {
        dispatch(closeDialog());
        throw response.payload.data;
      }
    } catch (err) {
      const message = err?.data?.message || err.message;
      return popup({
        icon: 'error',
        title: message ?? 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
        timer: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const getImageUrl = async () => {
    await dispatch(fetchSlipImageUrl({fileName: slipRequest.fileName, userId: slipRequest.userUuid}));
  };

  useEffect(() => {
    if (!imageUrl) {
      getImageUrl();
    }
  }, [slipRequest]);

  return (
    <div>
      <div className="content">
        <h1 className="text-center text-xl text-[#00c851]">
          <p>คุณต้องการที่จะอนุมัติรายการฝากด้วยสลิป</p>
          <p>รายการนี้ใช่หรือไหม ?</p>
        </h1>

        <div className="py-4">
          <div id="Slip" className="flex justify-center items-center">
            <img src={imageUrl} alt={'slip'} className="rounded-md w-96" />
          </div>

          <h3 className="text-red-500 text-center mt-2 mb-4">!! กรุณาตรวจสอบข้อมูลรายละเอียดก่อนลบรายการ</h3>

          <div className="grid grid-cols-1 gap-3">
            <UserInformation information={slipRequest?.user} />

            <SlipComparisonDetail slipRequest={slipRequest} />

            <NearbyDepositTransaction
              userUuid={slipRequest?.user?.uuid}
              amount={slipRequest?.amount}
              transactionDate={slipRequest?.transactionDate}
            />
          </div>
        </div>
      </div>
      <section id="aciton" className="grid grid-cols-2 gap-2">
        <Button className={classes.cancelBtn} onClick={handleCancel} disabled={loading}>
          ปิด
        </Button>
        <Button className={classes.confirmBtn} onClick={handleApproveSlipRequest} disabled={loading}>
          {loading ? <Spinner size={20} /> : 'ยันยันการฝาก'}
        </Button>
      </section>
    </div>
  );
};

export default ApproveSlipRequestDialog;
