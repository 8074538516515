import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Provider} from 'react-redux';

// ! Don't move it. store must be before ./App
import store from 'store';

// ! Must be after store
import App from './App';
import DateUtils from '@date-io/moment';
// import reportWebVitals from './reportWebVitals';
import SocketProvider from 'contexts/Socket/Provider';

import './index.css';
import 'utils/firebase.util';

const rootNode = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider libInstance={moment} utils={DateUtils} locale={'th'}>
      <BrowserRouter>
        <SocketProvider>
          <App />
        </SocketProvider>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Provider>,
  rootNode
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
