const initState = {
  deposit_shift1: {},
  deposit_shift2: {},
  withdraw_shift1: {},
  withdraw_shift2: {},
  withdraw_manual_shift1: {},
  withdraw_manual_shift2: {},
  deposit_notfound_shift1: {},
  deposit_notfound_shift2: {},
  // sumBayDeposit_Shift1: 0,
  // sumBayDeposit_Shift2: 0,
  // sumExtraDeposit1_Shift1: 0,
  // sumExtraDeposit1_Shift2: 0,
  // sumKbankDeposit_Shift1: 0,
  // sumKbankDeposit_Shift2: 0,
  // sumScbDeposit_Shift1: 0,
  // sumScbDeposit_Shift2: 0,
  // sumScbDeposit2_Shift1: 0,
  // sumScbDeposit2_Shift2: 0,
  // sumScbDeposit3_Shift1: 0,
  // sumScbDeposit3_Shift2: 0,
  // sumScbWithdraw1_Shift1: 0,
  // sumScbWithdraw1_Shift2: 0,
  // sumScbWithdraw2_Shift1: 0,
  // sumScbWithdraw2_Shift2: 0,
  // sumScbWithdraw3_Shift1: 0,
  // sumScbWithdraw3_Shift2: 0,
  // sumManualWithdraw_Shift1: 0,
  // sumManualWithdraw_Shift2: 0,
  loading: true,
};

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getDeposit_shift1':
      return {
        ...state,
        deposit_shift1: action.payload,
      };
    case 'getDeposit_shift2':
      return {
        ...state,
        deposit_shift2: action.payload,
      };
    case 'getDepositNotfound_shift1':
      return {
        ...state,
        deposit_notfound_shift1: action.payload,
      };
    case 'getDepositNotfound_shift2':
      return {
        ...state,
        deposit_notfound_shift2: action.payload,
      };
    case 'getWithdraw_shift1':
      return {
        ...state,
        withdraw_shift1: action.payload,
      };
    case 'getWithdraw_shift2':
      return {
        ...state,
        withdraw_shift2: action.payload,
      };
    case 'getWithdrawManual_shift1':
      return {
        ...state,
        withdraw_manual_shift1: action.payload,
      };
    case 'getWithdrawManual_shift2':
      return {
        ...state,
        withdraw_manual_shift2: action.payload,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
