const initState = {
  rewardList: null,
  creditList: null,
  loading: true,
};

const rewardReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getRewardRequestList':
      var rewards = action.res.data.data;
      // var rewardData = [];

      // rewards?.forEach((reward) => {
      //   rewardData.push(reward);
      // });

      return {
        ...state,
        rewardList: rewards,
        loading: false,
      };
    case 'getCreditRequestList':
      var credits = action.res.data.data;
      // var creditData = [];

      // credits?.forEach((credit) => {
      //   creditData.push(credit);
      // });

      return {
        ...state,
        creditList: credits,
        loading: false,
      };
    default:
      return state;
  }
};
export default rewardReducer;
