import DashboardLayout from 'layouts/DashboardLayout';
import get from 'lodash/get';
import MainLayout from 'layouts/MainLayout';
import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

// =============================================================
// import AdminView from './views/admin/AdminView';
// import CreditView from './views/reward/CreditView';
// import DashboardView from './views/dashboard/DashboardView';
// import DepositBankView from './views/deposit/DepositBankView';
// import DepositView from './views/deposit/DepositView';

// import Maintenance from 'containers/Maintennace';
// import ReportView from './views/report/ReportView';
// import RewardSettingView from './views/setting/RewardSettingView';
// import RewardView from './views/reward/RewardView';
// import SettingView from './views/setting/SettingView';
// import Shutdown from 'containers/Shutdown';
// import UserDetailView from './views/user/UserDetailView';
// import UserView from './views/user/UserView';
// import WithdrawBankView from './views/withdraw/WithdrawBankView';
// import WithdrawView from './views/withdraw/WithdrawView';

// import WheelSettingView from './views/setting/WheelSettingView';

const DashboardView = lazy(() => import('containers/Dashboard'));
// const ReportView = lazy(() => import('views/report/ReportView'));
const NewReportView = lazy(() => import('containers/Report/index'));
const UserView = lazy(() => import('views/user/UserView'));
const DepositView = lazy(() => import('containers/Deposit'));
const SearchDepositView = lazy(() => import('containers/Search/Deposit'));
// const WithdrawView = lazy(() => import('views/withdraw/WithdrawView'));
const Withdraw = lazy(() => import('containers/Withdraw'));
const CreditView = lazy(() => import('views/reward/CreditView'));
const AdminView = lazy(() => import('views/admin/AdminView'));
const Transfer = lazy(() => import('containers/Transfer'));
const ChangeLog = lazy(() => import('containers/ChangeLog'));
// const Logs = lazy(() => import('views/logs/LogsView'));
const LogsWithAdmin = lazy(() => import('views/logs/LogsWithAdminView'));
const ManualRegister = lazy(() => import('containers/ManualRegister'));

const SettingView = lazy(() => import('views/setting/SettingView'));
const RewardSettingView = lazy(() => import('views/setting/RewardSettingView'));
const RewardView = lazy(() => import('views/reward/RewardView'));
const WheelSettingView = lazy(() => import('views/setting/WheelSettingView'));
const BankSetting = lazy(() => import('containers/Setting/Bank'));
const GameSetting = lazy(() => import('containers/Setting/Game'));
const GameDetailSetting = lazy(() => import('containers/Setting/Game/GameDetail'));
const Announcement = lazy(() => import('containers/Setting/Announcement'));
const AddAnnouncement = lazy(() => import('containers/Setting/Announcement/AddAnnouncement'));
const EditAnnouncement = lazy(() => import('containers/Setting/Announcement/EditAnnouncement'));
const Customization = lazy(() => import('containers/Setting/Customization'));
const Slip = lazy(() => import('containers/Slip'));
const VerifySlip = lazy(() => import('containers/Slip/VerifySlip'));

const Logs = lazy(() => import('containers/Logs/LogsView'));

const DepositBankView = lazy(() => import('views/deposit/DepositBankView'));
const UserDetailView = lazy(() => import('views/user/UserDetailView'));
const WithdrawBankView = lazy(() => import('views/withdraw/WithdrawBankView'));

const LoginView = lazy(() => import('views/auth/LoginView'));
const Maintenance = lazy(() => import('containers/Maintennace'));
const Shutdown = lazy(() => import('containers/Shutdown'));
const NotFoundView = lazy(() => import('views/errors/NotFoundView'));
const Operational = lazy(() => import('containers/Operational'));
const BackOfficeOperational = lazy(() => import('containers/Operational/BackOffice'));
const WebsiteOperational = lazy(() => import('containers/Operational/Website'));

const getAdminElement = (status, isLoggedIn) => {
  if (status === 'MA') {
    return <Navigate to="/ma" />;
  } else if (status === 'SHUTDOWN') {
    return <Navigate to="/shutdown" />;
  } else if (isLoggedIn) {
    if (status === 'SHUTDOWN') {
      return <Navigate to="/shutdown" />;
    } else {
      return <DashboardLayout />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

const getElement = (status, element) => {
  if (status === 'MA') {
    return <Maintenance />;
  } else if (status === 'SHUTDOWN') {
    return <Shutdown />;
  } else {
    return element;
  }
};

const routes = (isLoggedIn, setting) => {
  const status = get(setting, 'backOfficeStatus', '');

  return [
    {
      path: 'admin',
      element: getAdminElement(status, isLoggedIn),
      children: [
        {path: 'user', element: <UserView />},
        {path: 'userdetail/:username', element: <UserDetailView />},
        {path: 'dashboard', element: <DashboardView />},
        {path: 'report', element: <NewReportView />},
        {path: 'register', element: <ManualRegister />},
        {path: 'deposit', element: <DepositView />},
        {path: 'search', element: <SearchDepositView />},
        {path: 'withdraw', element: <Withdraw />},
        {path: 'reward', element: <RewardView />},
        {path: 'credit', element: <CreditView />},
        {path: 'deposit_bank', element: <DepositBankView />},
        {path: 'withdraw_bank', element: <WithdrawBankView />},
        {path: 'admin_list', element: <AdminView />},
        {path: 'logs', element: <Logs />},
        {path: 'logs/:uuid', element: <LogsWithAdmin />},
        {path: 'transfer', element: <Transfer />},
        {path: 'changeLog', element: <ChangeLog />},
        {path: 'slip', element: <Slip />},
        {path: 'slip/verify', element: <VerifySlip />},
        {path: 'setting', element: <SettingView />},
        {path: 'setting/customization', element: <Customization />},
        {path: 'setting/reward', element: <RewardSettingView />},
        {path: 'setting/wheel', element: <WheelSettingView />},
        {path: 'setting/bank', element: <BankSetting />},
        {path: 'setting/game', element: <GameSetting />},
        {path: 'setting/game/detail/:gameId', element: <GameDetailSetting />},
        {path: 'setting/announcement', element: <Announcement />},
        {path: 'setting/announcement/create', element: <AddAnnouncement />},
        {path: 'setting/announcement/edit/:id', element: <EditAnnouncement />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {path: 'login', element: getElement(status, <LoginView />)},
        {path: 'ma', element: <Maintenance />},
        {path: 'shutdown', element: <Shutdown />},
        {path: '404', element: <NotFoundView />},
        {path: 'operational', element: <Operational />},
        {path: 'operational/back-office', element: <BackOfficeOperational />},
        {path: 'operational/website', element: <WebsiteOperational />},
        {path: '/', element: <Navigate to="/admin/dashboard" />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
  ];
};

export default routes;
