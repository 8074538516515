import alfa555 from 'configs/env/alfa555.config';
import area88bet from 'configs/env/area88bet.config';
import cigar88 from 'configs/env/cigar88.config';
import cr77bet from 'configs/env/cr77bet.config';
import europa168 from 'configs/env/europa168.config';
import fasttobet from 'configs/env/fasttobet.config';
import gicc from 'configs/env/gicc.config';
import gimi168 from 'configs/env/gimi168.config';
import gimi44 from 'configs/env/gimi44.config';
import gimi88 from 'configs/env/gimi88.config';
import gimixo from 'configs/env/gimixo.config';
import gkbet888 from 'configs/env/gkbet888.config';
import ifinclub from 'configs/env/ifinclub.config';
import inw88 from 'configs/env/inw88.config';
import jumperbet from 'configs/env/jumperbet.config';
import mummy55 from 'configs/env/mummy55.config';
import pap55 from 'configs/env/pap55.config';
import pglike from 'configs/env/pglike.config';
import reboost88 from 'configs/env/reboost88.config';
import saipay69 from 'configs/env/saipay69.config';
import xobet88 from 'configs/env/xobet98.config';

import {CLIENT_IDS} from 'constants/index';

import navBarRoutes from './navBarRoutes';

const WEB_URL = process.env.REACT_APP_WEB_URL;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const TRANSFER_TO_WITHDRAW = process.env.REACT_APP_TRANSFER_TO_WITHDRAW;

// Other
const BAY_DEPOSIT = process.env.REACT_APP_BAY_DEPOSIT;
const KBANK_DEPOSIT = process.env.REACT_APP_KBANK_DEPOSIT;
const KTB_DEPOSIT = process.env.REACT_APP_KTB_DEPOSIT;

// SCB DEPOSIT
const SCB_DEPOSIT = process.env.REACT_APP_SCB_DEPOSIT;
const SCB_DEPOSIT_2 = process.env.REACT_APP_SCB_DEPOSIT_2;
const SCB_DEPOSIT_3 = process.env.REACT_APP_SCB_DEPOSIT_3;

// SCB WITHDRAW
const SCB_WITHDRAW_1 = process.env.REACT_APP_SCB_WITHDRAW_1;
const SCB_WITHDRAW_2 = process.env.REACT_APP_SCB_WITHDRAW_2;
const SCB_WITHDRAW_3 = process.env.REACT_APP_SCB_WITHDRAW_3;

const envToBoolean = envVariable => {
  return envVariable?.toUpperCase() === 'TRUE';
};

const envConfig = {
  [CLIENT_IDS.GIMI88]: gimi88,
  [CLIENT_IDS.GIMI168]: gimi168,
  [CLIENT_IDS.GICC]: gicc,
  [CLIENT_IDS.GIMI44]: gimi44,
  [CLIENT_IDS.GIMIXO]: gimixo,
  [CLIENT_IDS.CR77BET]: cr77bet,
  [CLIENT_IDS.EUROPA168]: europa168,
  [CLIENT_IDS.FASTTOBET]: fasttobet,
  [CLIENT_IDS.GKBET888]: gkbet888,
  [CLIENT_IDS.PAP55]: pap55,
  [CLIENT_IDS.ALFA555]: alfa555,
  [CLIENT_IDS.IFINCLUB]: ifinclub,
  [CLIENT_IDS.REBOOST88]: reboost88,
  [CLIENT_IDS.AREA88BET]: area88bet,
  [CLIENT_IDS.XOBET98]: xobet88,
  [CLIENT_IDS.PGLIKE]: pglike,
  [CLIENT_IDS.SAIPAY69]: saipay69,
  [CLIENT_IDS.CIGAR88]: cigar88,
  [CLIENT_IDS.JUMPERBET]: jumperbet,
  [CLIENT_IDS.INW88]: inw88,
  [CLIENT_IDS.MUMMY55]: mummy55,
}[CLIENT_ID];

const config = {
  webUrl: WEB_URL,
  actionUrl: SERVER_URL,
  logoName: CLIENT_ID,
  transferToDepositBank: envToBoolean(TRANSFER_TO_WITHDRAW),
  bayDeposit: {
    id: 3,
    bank: 'BAY',
    active: envToBoolean(BAY_DEPOSIT),
  },
  kbankDeposit: {
    id: 2,
    bank: 'KBANK',
    active: envToBoolean(KBANK_DEPOSIT),
  },
  scbDeposit: {
    id: 1,
    bank: 'SCB',
    active: envToBoolean(SCB_DEPOSIT),
  },
  scbDeposit2: {
    id: 7,
    bank: 'SCB',
    active: envToBoolean(SCB_DEPOSIT_2),
  },
  scbDeposit3: {
    id: 8,
    bank: 'SCB',
    active: envToBoolean(SCB_DEPOSIT_3),
  },
  ktbDeposit: {
    id: 10,
    bank: 'KTB',
    active: envToBoolean(KTB_DEPOSIT),
  },
  scbWithdraw1: {
    id: 4,
    bank: 'scbWithdraw1',
    withdrawId: 4,
    active: envToBoolean(SCB_WITHDRAW_1),
  },
  scbWithdraw2: {
    id: 6,
    bank: 'scbWithdraw2',
    withdrawId: 6,
    active: envToBoolean(SCB_WITHDRAW_2),
  },
  scbWithdraw3: {
    id: 9,
    bank: 'scbWithdraw3',
    active: envToBoolean(SCB_WITHDRAW_3),
  },
  navBarRoutes,
  envConfig,
};

export default config;
