import {cn} from 'utils/css.util';

const StatusBadge = ({children, className}) => {
  return <div className={cn(`px-3 py-1 rounded-full min-w-[100px] text-center text-sm`, className)}>{children}</div>;
};

/**
 * Status for slip
 * @param {object} props
 * @param {string} props.status VERIFIED, FAIL
 */
export const SlipStatus = ({status}) => {
  switch (status) {
    case 'VERIFIED':
      return <StatusBadge className={'bg-green-500'}>สลิปถูกต้อง</StatusBadge>;
    case 'FAIL':
      return <StatusBadge className={'bg-red-500'}>เชื่อมธนาคารไม่สำเร็จ</StatusBadge>;
    default:
      return '';
  }
};

/**
 * Status for slip
 * @param {object} props
 * @param {string} props.status WAITING, NOT FOUND and READY
 */
export const SlipDepositPrepareStatus = ({status}) => {
  switch (status) {
    case 'WAITING':
      return <StatusBadge className={'bg-[#3378e7]'}>รอตรวจสอบ</StatusBadge>;
    case 'NOT FOUND':
      return <StatusBadge className={'bg-[#ffa100]'}>Not found</StatusBadge>;
    case 'READY':
      return <StatusBadge className={'bg-[#00c851]'}>ปกติ</StatusBadge>;
    default:
      return '';
  }
};

/**
 * Status for slip history
 * @param {object} props
 * @param {string} props.status SUCCEED and CANCELLED
 */
export const SlipHistoryStatus = ({status}) => {
  switch (status) {
    case 'SUCCEED':
      return <StatusBadge className={'bg-green-500'}>สำเร็จ</StatusBadge>;
    case 'CANCELLED':
      return <StatusBadge className={'bg-red-500'}>ยกเลิก</StatusBadge>;
    default:
      return '';
  }
};
