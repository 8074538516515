import AddNotFound from './AddNotFound';
import React from 'react';
import {DEPOSIT_STATUS} from 'constants/index';
import {fetchDepositFail, fetchDepositTimeout} from 'store/deposit.slice';
import {makeStyles, Paper} from '@material-ui/core';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  [DEPOSIT_STATUS.FAIL]: {
    cursor: 'default',
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.error,
  },
  [DEPOSIT_STATUS.NOT_FOUND]: {
    cursor: 'default',
    backgroundColor: '#ffa100',
    padding: '4px 0 4px 0',
  },
  [DEPOSIT_STATUS.SUCCESS]: {
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.success,
  },
  [DEPOSIT_STATUS.TIMEOUT]: {
    cursor: 'default',
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.timeout,
  },
  [DEPOSIT_STATUS.FAIL_THEN_SUCCESS]: {
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.failThenSuccess,
  },
  [DEPOSIT_STATUS.TIMEOUT_THEN_SUCCESS]: {
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.timeoutThenSuccess,
  },
  [DEPOSIT_STATUS.NOTFOUND_THEN_SUCCESS]: {
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.notfoundThenSuccess,
  },
  [DEPOSIT_STATUS.MANUAL]: {
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.manualThenSuccess,
  },
  [DEPOSIT_STATUS.MANUAL_SLIP_ADMIN]: {
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.success,
  },
  [DEPOSIT_STATUS.MANUAL_SLIP_USER]: {
    padding: '4px 0 4px 0',
    backgroundColor: theme.palette.background.success,
  },
}));

const Fail = props => {
  const {isDeposit, status, id, done} = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = () => {
    if (window.confirm('คุณต้องการเติมเงินหรือไม่')) {
      dispatch(fetchDepositFail({id}))
        .then(unwrapResult)
        .then(res => {
          if (!res.success) {
            alert(res.message);
            return;
          }
          done();
          alert(res.message);
        });
    }
  };

  return (
    <Paper elevation={3} className={classes[status]} onClick={isDeposit ? handleClick : void 0}>
      ล้มเหลว
    </Paper>
  );
};

const Notfound = props => {
  const {isDeposit, status, id, done} = props;
  const classes = useStyles();

  if (isDeposit) {
    return <AddNotFound id={id} done={done} />;
  }

  return (
    <Paper elevation={3} className={classes[status]}>
      Not found
    </Paper>
  );
};

const Timeout = props => {
  const dispatch = useDispatch();
  const {isDeposit, status, id, done} = props;
  const classes = useStyles();

  const handleClick = () => {
    if (window.confirm('คุณต้องการเติมเงินหรือไม่')) {
      dispatch(fetchDepositTimeout({id}))
        .then(unwrapResult)
        .then(res => {
          if (!res.success) {
            alert(res.message);
            return;
          }
          done();
          alert(res.message);
        });
    }
  };

  return (
    <Paper elevation={3} className={classes[status]} onClick={isDeposit ? handleClick : void 0}>
      TIMEOUT
    </Paper>
  );
};

const Success = props => {
  const classes = useStyles();
  const {status} = props;

  const successFrom = () => {
    switch (Number(status)) {
      case 9: {
        return 'ด้วย Admin Slip';
      }
      case 10: {
        return 'ด้วย User Slip';
      }
      default:
        return '';
    }
  };

  return (
    <Paper elevation={3} className={classes[status]}>
      สำเร็จ {successFrom()}
    </Paper>
  );
};

const Default = props => {
  const {status} = props;
  return <Paper>{status}</Paper>;
};

const Status = props => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const {isDeposit} = currentUser;
  const {
    deposit: {id, status},
    done,
  } = props;

  const statusMap = {
    [DEPOSIT_STATUS.FAIL]: <Fail isDeposit={isDeposit} id={id} status={status} done={done} />,
    [DEPOSIT_STATUS.SUCCESS]: <Success status={status} />,
    [DEPOSIT_STATUS.NOT_FOUND]: <Notfound isDeposit={isDeposit} id={id} status={status} done={done} />,
    [DEPOSIT_STATUS.TIMEOUT]: <Timeout isDeposit={isDeposit} id={id} status={status} done={done} />,
    [DEPOSIT_STATUS.FAIL_THEN_SUCCESS]: <Success status={status} />,
    [DEPOSIT_STATUS.MANUAL]: <Success status={status} />,
    [DEPOSIT_STATUS.NOTFOUND_THEN_SUCCESS]: <Success status={status} />,
    [DEPOSIT_STATUS.TIMEOUT_THEN_SUCCESS]: <Success status={status} />,
    [DEPOSIT_STATUS.MANUAL_SLIP_ADMIN]: <Success status={status} />,
    [DEPOSIT_STATUS.MANUAL_SLIP_USER]: <Success status={status} />,
    DEFAULT: <Default status={status} />,
  };

  return <div>{statusMap[status] || statusMap['DEFAULT']}</div>;
};

export default Status;
