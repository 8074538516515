import React from 'react';
import ReactSelect from 'react-select';

// const options = [
//   {value: 'chocolate', label: 'Chocolate'},
//   {value: 'strawberry', label: 'Strawberry'},
//   {value: 'vanilla', label: 'Vanilla'},
// ];
// const colourOptions = [
//   {value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true},
//   {value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true},
//   {value: 'purple', label: 'Purple', color: '#5243AA'},
//   {value: 'red', label: 'Red', color: '#FF5630', isFixed: true},
//   {value: 'orange', label: 'Orange', color: '#FF8B00'},
//   {value: 'yellow', label: 'Yellow', color: '#FFC400'},
//   {value: 'green', label: 'Green', color: '#36B37E'},
//   {value: 'forest', label: 'Forest', color: '#00875A'},
//   {value: 'slate', label: 'Slate', color: '#253858'},
//   {value: 'silver', label: 'Silver', color: '#666666'},
// ];

const customStyles = {
  control: (styles, {isFocused}) => ({
    ...styles,
    display: 'flex',
    alignContent: 'center',
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: '#121212',
    borderColor: '#121212',
    boxShadow: isFocused && '#555555',
    width: 'auto',
    height: 40,
  }),
  option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    fontSize: 16,
    backgroundColor: isSelected ? '#555555' : isFocused ? '#ebedf3' : null,
    color: isSelected ? '#ffffff' : '#000000',
  }),
  menu: provided => ({...provided, zIndex: 20}),
  input: styles => ({...styles, color: '#ffffff'}),
  placeholder: styles => ({
    ...styles,
    ':before': {
      color: '#30599F',
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  }),
  singleValue: base => ({...base, color: '#4977ec'}),
  multiValue: (base, state) => {
    return state.data.isFixed ? {...base, backgroundColor: 'gray'} : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? {...base, fontWeight: 'bold', color: 'white', paddingRight: 6} : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? {...base, display: 'none'} : base;
  },
};

const SelectBlack = props => {
  return <ReactSelect classNamePrefix="select" styles={customStyles} placeholder="เลือก" {...props} />;
};

export {SelectBlack};
