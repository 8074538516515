import {makeStyles} from '@material-ui/core';

import Input from 'components/shared/Input';

const useStyles = makeStyles(theme => ({
  textFieldColor: {
    color: '#4285f4',
    backgroundColor: theme.palette.background.grey1,
  },
}));

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: {touched, errors, handleBlur, handleChange}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();
  return (
    <Input
      fullWidth
      size="small"
      margin="normal"
      variant="outlined"
      onBlur={handleBlur}
      style={{marginTop: 8}}
      onChange={handleChange}
      onWheel={e => e.target.blur()}
      InputProps={{
        className: classes.textFieldColor,
      }}
      helperText={touched[field.name] && errors[field.name]}
      error={Boolean(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
  );
};
