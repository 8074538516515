import get from 'lodash/get';
import {io} from 'socket.io-client';

const {REACT_APP_SERVER_URL} = process.env;
let socket;

export const initialSocket = data => {
  const _token = get(data, 'token') || localStorage.getItem('token');
  const _user = get(data, 'user') || JSON.parse(localStorage.getItem('user'));
  const server = REACT_APP_SERVER_URL.substring(0, REACT_APP_SERVER_URL.length - 4);
  const lv = get(_user, 'lv');
  const SOCKET_URL = `${server}/${lv === 0 || lv === 10 ? 'admin' : ''}`;

  if (_token) {
    // console.log('set socket!');
    socket = io(SOCKET_URL, {
      transports: ['websocket'],
      auth: {
        token: _token || '',
        uuid: get(_user, 'uuid', ''),
      },
      reconnectionAttempts: 3,
      reconnectionDelayMax: 10000,
      cors: {
        origin: server,
        methods: ['GET', 'POST'],
      },
    });

    socket?.on('connect', () => {
      // console.log(socket.id);
    });

    return socket;
  }
};

export const subscribeToChannel = (channel, cb) => {
  if (!socket) {
    // console.log('notfound socket!');
    return true;
  }

  // console.log(`subscribe ${channel}`);

  socket.on(channel, msg => {
    // console.log('Websocket event received!', msg);
    return cb(null, msg);
  });
};

export const unsubscribeToChannel = (channel, cb) => {
  if (socket) {
    // console.log('unsub..');
    socket.off(channel, cb);
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket.removeAllListeners();
  }
};

socket?.on('connect', () => {
  // console.log(socket.id);
});

socket?.on('disconnect', () => {
  // console.log(socket.id, socket.connected); // undefined false
});

// err
socket?.on('connect_error', () => {
  // console.log(err.message); // prints the message associated with the error
});

export default socket;
