import React from 'react';
import {Box, Button, Dialog, DialogContent, Grid, Paper, TextField, Typography} from '@material-ui/core';
import {fetchDepositNotfound} from 'store/deposit.slice';
import {Form, Formik} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';

// eslint-disable-next-line no-undef
const {REACT_APP_MERCHANT_ID} = process.env;

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.grey2,
  },
  textColor: {
    color: 'white',
  },
  textFieldColor: {
    color: '#4285f4',
    backgroundColor: theme.palette.background.grey1,
  },
}));

const AddNotFound = ({id, done}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddNotFoundDepositSchema = Yup.object().shape({
    username: Yup.string().min(9, 'กรุณากรอกข้อมูลรหัสให้ถูกต้อง').max(20).required('กรุณากรอกรหัส'),
  });

  const body = (
    <Formik
      validationSchema={AddNotFoundDepositSchema} // กำหนด validation
      initialValues={{
        username: REACT_APP_MERCHANT_ID,
      }}
      onSubmit={values => {
        dispatch(fetchDepositNotfound({...values, id}))
          .then(unwrapResult)
          .then(res => {
            if (!res.success) {
              alert(res.message);
              return;
            }
            done();
            alert(res?.message);
          })
          .catch(error => {
            alert(error.toString());
          })
          .finally(() => {
            handleClose();
          });
      }}>
      {({errors, touched, values, handleBlur, handleChange}) => (
        <Form>
          <Box mt={3} mb={3}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Typography className={classes.textColor} variant="h3">
                เติม Not Found
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              รหัส
            </Typography>
            <TextField
              placeholder={REACT_APP_MERCHANT_ID}
              error={Boolean(touched.username && errors.username)}
              fullWidth
              size="small"
              helperText={touched.username && errors.username}
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.username}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{marginTop: 8}}
            />
          </Box>

          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{marginBottom: 16}}>
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{backgroundColor: '#B23CFD', color: 'white'}}>
              ปิด
            </Button>
            <Button type="submit" variant="contained" size="large" style={{backgroundColor: '#4285f4', color: 'white'}}>
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Paper
        className="cursor-default"
        style={{backgroundColor: '#ffa100', padding: '4px 0 4px 0'}}
        onClick={handleClickOpen}>
        Not found
      </Paper>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

export default AddNotFound;
