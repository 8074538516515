import Pull from './Pull';
import React from 'react';
import Status from './Status';
import User from './User';
import {moment} from 'utils/date.util';
import {numberFormat} from 'utils/format-number.util';

export const columns = [
  {
    id: 'id',
    label: '#',
    align: 'center',
  },
  {
    id: 'status',
    label: 'สถานะ',
    align: 'center',
    width: 105,
    // eslint-disable-next-line react/display-name
    format: (value, record, done) => <Status deposit={record} done={done} />,
  },
  {
    id: 'transactionDate',
    label: 'วันที่',
    align: 'center',
    width: 150,
    format: value => moment(value).format('DD/MM/YYYY HH:mm'),
  },
  {
    id: 'user.inviter',
    label: 'ผู้แนะนำ',
    align: 'center',
  },
  {
    id: 'user.user_pass.username',
    label: 'รหัส',
    align: 'center',
    width: 200,
    // eslint-disable-next-line react/display-name
    format: (value, record) => <User data={record} path={{username: 'user.user_pass.username', level: 'user.level'}} />,
  },
  {
    id: 'fromAccount',
    label: 'โอนจาก',
    width: 100,
  },
  {
    id: 'amount',
    label: 'จำนวน',
    align: 'right',
    format: value => numberFormat(value),
  },
  {
    id: 'bank',
    label: 'เข้าบัญชี',
    width: 100,
    format: value => `${value?.bankName} ${value?.bankId?.substring(6)}`,
  },
  {
    id: 'note',
    label: 'หมายเหตุ',
  },
  {
    id: 'action',
    label: 'action',
    width: 100,
    // eslint-disable-next-line react/display-name
    format: (value, record, done) => <Pull deposit={record} done={done} />,
  },
];

export const columnsDepositManual = [
  {
    id: 'id',
    label: '#',
    align: 'center',
  },
  {
    id: 'transactionDate',
    label: 'วันที่',
    align: 'center',
    width: 150,
    format: value => moment(value).format('DD/MM/YYYY HH:mm'),
  },
  {
    id: 'amount',
    label: 'จำนวน',
    align: 'right',
    format: value => numberFormat(value),
  },
  {
    id: 'bank',
    label: 'เข้าบัญชี',
    width: 100,
    format: value => `${value?.bankName}`,
  },
  {
    id: 'status',
    label: 'สถานะ',
    align: 'center',
    width: 105,
    // eslint-disable-next-line react/display-name
    format: (value, record) => <Status deposit={record} done={void 0} />,
  },
];
