import {PropTypes} from 'prop-types';

import Copy from 'components/shared/Copy';
import DataCard from 'components/shared/DataCard';
import {Separator} from 'components/shared/Separator';
import {Typography} from 'components/shared/Typography';

const UserInformation = ({information}) => {
  return (
    <div>
      <Typography className="mb-2 mt-4 text-xl">ข้อมูลผู้เล่น</Typography>
      <Separator />

      <DataCard className="flex justify-between items-center">
        <DataCard.Title>ชื่อ</DataCard.Title>
        <DataCard.Value>{information?.name}</DataCard.Value>
      </DataCard>

      <DataCard className="flex justify-between items-center">
        <DataCard.Title>ยูสเซอร์เนม</DataCard.Title>
        <DataCard.Value>
          <Copy text={information?.user_pass?.username}>{information?.user_pass?.username}</Copy>
        </DataCard.Value>
      </DataCard>
    </div>
  );
};

UserInformation.propTypes = {
  information: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    user_bank: PropTypes.shape({
      bankId: PropTypes.string,
      bankName: PropTypes.string,
    }),
    user_pass: PropTypes.shape({
      username: PropTypes.string,
    }),
  }),
};

export default UserInformation;
