export default {
  CLIENT_ID: 'CIGAR88',
  firebaseConfig: {
    apiKey: 'AIzaSyC347ABrUFowKSMpSEkkfV0Z-y2f84Jyc4',
    authDomain: 'gimi-web-agent-v2.firebaseapp.com',
    projectId: 'gimi-web-agent-v2',
    storageBucket: 'gimi-web-agent-v2.appspot.com',
    messagingSenderId: '132307404856',
    appId: '1:132307404856:web:0faf9d9b016b8b210afecd',
    measurementId: 'G-16ZHF6ZEN4',
  },
};
