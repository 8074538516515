export function listAnnouncement(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/announcement/list',
    params: payload,
    cancelToken,
  };
}

export function getAnnouncementById(id, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/announcement/${id}`,
    cancelToken,
  };
}

export function createAnnouncement(payload) {
  return {
    method: 'POST',
    endpoint: '/secure/announcement/create',
    data: payload,
  };
}

export function updateAnnouncement(id, payload) {
  return {
    method: 'PATCH',
    endpoint: `/secure/announcement/update/${id}`,
    data: payload,
  };
}
export function deleteAnnouncement(id) {
  return {
    method: 'DELETE',
    endpoint: `/secure/announcement/delete/${id}`,
  };
}
