import {cn} from 'utils/css.util';

/**
 * Badge component for show status
 * @param {object} props
 * @param {string} props.children
 * @param {string} props.className
 * @param {string} props.variant // default, success, fail, warning
 * @returns {JSX.Element}
 */
const Badge = ({children, className, variant = 'default'}) => {
  const backGroundColor = {
    default: 'bg-transparent',
    success: 'bg-green-500',
    fail: 'bg-red-500',
    warning: 'bg-orange-500',
  };

  return (
    <div
      className={cn(' min-w-[100px] py-1 px-3 rounded-full text-center text-sm', backGroundColor[variant], className)}>
      {children}
    </div>
  );
};

export default Badge;
