const initState = {
  adminList: null,
  loading: true,
  adminOption: [{value: 'ALL', label: 'ทั้งหมด'}],
};

const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getAdminList':
      var admins = action.res.data.data;
      var adminData = [];

      admins?.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      admins?.forEach(admin => {
        adminData.push(admin);
      });

      return {
        ...state,
        adminList: adminData,
        loading: false,
        adminOption: [
          ...initState.adminOption,
          ...admins.map(item => {
            return {value: item.uuid, label: item.username};
          }),
        ],
      };
    default:
      return state;
  }
};
export default adminReducer;
