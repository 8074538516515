const initState = {
  userList: null,
  userListMeta: {
    currentPage: 1,
    limit: 1000,
    total: 10,
  },
  userDetail: null,
  userDepositList: null,
  userWithdrawList: null,
  userWheelrawList: null,
  userAfDownline: {},
  userAfLog: [],
  userWinLoseLog: [],
  transfers: [],
  depositWithdraw: [],
  wallet: {},
  turnOver: {},
  winAndLose: {},
  loading: true,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getUserList':
      // var users = action.res.data.data;
      // var userData = [];
      //
      // users?.sort(function (a, b) {
      //   return new Date(b.createdAt) - new Date(a.createdAt);
      // });
      //
      // users.forEach(user => {
      //   userData.push(user);
      // });
      // console.log(users);

      return {
        ...state,
        userList: action.res.data.data.rows,
        userListMeta: {
          currentPage: action.res.data.data.currentPage,
          limit: action.res.data.data.limit,
          total: action?.res?.data?.data?.rows.length,
        },
        loading: false,
      };
    case 'getUserListByOther':
      return {
        ...state,
        userList: action.res?.data?.data,
        userListMeta: {
          total: action.res?.data?.data.length,
        },
        loading: false,
      };
    case 'getUserDetail':
      var userDetail = action.res.data.data[0];

      return {
        ...state,
        userDetail,
        wallet: action.res.data?.credit,
        upline: action.res.data?.upline,
        loading: false,
      };
    case 'getSumUserDeposit':
      var sumUserDeposit = action.res.data.data;

      return {
        ...state,
        sumUserDeposit: sumUserDeposit,
        loading: false,
      };
    case 'getSumUserWithdraw':
      var sumUserWithdraw = action.res.data.data;

      return {
        ...state,
        sumUserWithdraw: sumUserWithdraw,
        loading: false,
      };
    case 'getUserDepositList':
      // var userDeposit = action.res.data.data;
      // var userDepositData = [];
      //
      // userDeposit?.sort(function (a, b) {
      //   return new Date(b.CreationTime) - new Date(a.CreationTime);
      // });
      //
      // userDeposit?.forEach(userDepost => {
      //   userDepositData.push(userDepost);
      // });

      return {
        ...state,
        userDepositList: action.res.data.data,
        loading: false,
      };
    case 'getUserWithdrawList':
      return {
        ...state,
        userWithdrawList: action?.res?.data?.data,
        loading: false,
      };
    case 'getUserAfBalance':
      return {
        ...state,
        userAfBalance: action.res.data.data,
        loading: false,
      };
    case 'getUserAfDownline':
      return {
        ...state,
        userAfDownline: action.res.data.data,
        loading: false,
      };
    case 'getUserAfLog':
      return {
        ...state,
        userAfLog: action.res.data.data,
        loading: false,
      };
    case 'getUserWinLoseLog':
      return {
        ...state,
        userWinLoseLog: action.res.data.data,
        loading: false,
      };
    case 'getUserWheelList':
      return {
        ...state,
        userWheelList: action.res.data.data,
        loading: false,
      };
    case 'getTransfers':
      return {
        ...state,
        transfers: action?.res?.data,
      };
    case 'getDepositWithdraw':
      return {
        ...state,
        depositWithdraw: action?.res?.data,
      };
    case 'getTurnOver':
      return {
        ...state,
        turnOver: action?.res?.data?.data,
      };
    case 'getWinAndLose':
      return {
        ...state,
        winAndLose: action?.res?.data?.data,
      };
    case 'onUserLoading':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
export default userReducer;
