import adminReducer from './adminReducer';
import announcement from 'store/announcement.slice';
import authReducer from './authReducer';
import bank from 'store/bank.slice';
import configuration from 'store/configuration.slice';
import dashboard2 from 'store/dashboard2.slice';
import dashboardReducer from './dashboardReducer';
import depositReducer from 'store/deposit.slice';
import game from 'store/game.slice';
import global from 'store/global.slice';
import logReducer from './logReducer';
import logs from 'store/logs.slice';
import otherReducer from './otherReducer';
import register from 'store/register.slice';
import reward2 from 'store/reward2.slice';
import search from 'store/search.slice';
import settings from 'store/settings.slice';
import slip from 'store/slip.slice';
import uiProvider from 'store/uiProvider.slice';

// import reportReducer from "./reportReducer";
import report from 'store/report.slice';
import rewardReducer from './rewardReducer';
import settingReducer from './settingReducer';
import transferSlice from 'store/transfer.slice';
import userDetail from 'store/userDetail.slice';
import userReducer from './userReducer';
import withdrawReducer from 'store/withdraw.slice';
import {combineReducers} from 'redux';
// import {withdrawNotifyApi} from 'store/apis/socket.api';
const rootReducer = combineReducers({
  global,
  auth: authReducer,
  user: userReducer,
  deposit: depositReducer,
  withdraw: withdrawReducer,
  admin: adminReducer,
  log: logReducer,
  reward: rewardReducer,
  reward2,
  setting: settingReducer,
  dashboard: dashboardReducer,
  dashboard2,
  other: otherReducer,
  transfer: transferSlice,
  userDetail,
  bank,
  register,
  game,
  announcement,
  report,
  logs,
  slip,
  settings,
  uiProvider,
  search,
  configuration,
  // [withdrawNotifyApi.reducerPath]: withdrawNotifyApi.reducer,
});

export default rootReducer;
