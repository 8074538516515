import GlobalStyles from './components/GlobalStyles';
import React, {Suspense, useEffect, useLayoutEffect} from 'react';
import routes from './routes';

// ? Library built-in and add-on
import theme from './theme';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';

import {isEmpty} from 'lodash';
import {ThemeProvider} from '@material-ui/core';
import {ToastContainer} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {useRoutes} from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.min.css';

// ! Don't move it. Alway under library built-in
import {fetchSettingOperational} from 'store/settings.slice';
import {getSettingDetail} from 'store/actions/settingAction';

import UiProvider from 'providers/uiProvide';
import {fetchConfigurationList} from 'store/configuration.slice';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const contextClass = {
  success: 'bg-blue-600',
  error: 'bg-red-600',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

// const audio = new Audio('/static/sound/note.mp3');
// audio.load();

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const settingOperational = useSelector(state => state.settings.settingOperational.data);
  const setting = useSelector(state => state.setting.settingDetail);

  const isLoggedIn = !isEmpty(currentUser);
  const routing = useRoutes(routes(!isEmpty(currentUser), settingOperational));

  // const playSound = () => {
  // const audio = new Audio('/static/sound/note.mp3');
  // audio.play();
  // };

  // useEffect(() => {
  // getCurrentUser(dispatch);
  // }, []);

  /**
   * Deprecated: old way
   */
  // const getFirebaseSettingOperational = async () => {
  //   if (isLoggedIn && isEmpty(remoteConfig)) {
  //     // console.log('get remote config');
  //     await getConfig();
  //   }

  //   if (isLoggedIn && isEmpty(setting)) {
  //     getSettingDetail(dispatch);
  //   }

  //   // if (isLoggedIn && !socket) {
  //   //   initialSocket();
  //   // }
  // };

  const getSettingOperational = async () => {
    // FIXME: isLoggedIn
    if (isEmpty(settingOperational)) {
      dispatch(fetchSettingOperational());
    }
  };

  useEffect(() => {
    if (isLoggedIn && isEmpty(setting)) {
      getSettingDetail(dispatch);
      dispatch(fetchConfigurationList());
    }
  }, []);

  useLayoutEffect(() => {
    getSettingOperational();
    // getFirebaseSettingOperational();
    // return () => disconnectSocket();
  }, [currentUser]);

  // useEffect(() => {
  //   const listener = (err, payload) => {
  //     if (err) return;
  //     console.log(payload);
  //     const username = get(payload, 'user.user_pass.username');
  //     const amount = get(payload, 'amount');
  //     toast(`${username} แจ้งถอน ${amount}`, {onClick: () => navigate('/admin/withdraw')});
  //     // audio.play();
  //   };
  //
  //   if (isLoggedIn) {
  //     // socket?.on(EVENT.WITHDRAW_NOTIFY, listener);
  //     subscribeToChannel(EVENT.WITHDRAW_NOTIFY, listener);
  //   }
  //
  //   return () => unsubscribeToChannel(EVENT.WITHDRAW_NOTIFY, listener);
  // }, [currentUser]);

  // console.log(process.env.NODE_ENV, process.env.REACT_APP_CLIENT_ID, remoteConfig);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <UiProvider />
      <ToastContainer
        limit={20}
        autoClose={5000}
        role="alert"
        draggablePercent={60}
        position="bottom-left"
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        progress={undefined}
        toastClassName={({type}) =>
          contextClass[type || 'default'] +
          ' relative flex p-1 my-2 min-h-12 rounded-md justify-between overflow-hidden cursor-pointer z-40'
        }
        progressClassName="fancy-progress-bar"
      />
      <Suspense
        fallback={
          <div className="flex flex-col justify-center items-center h-full bg-black">
            <p className="text-white">Loading..</p>
          </div>
        }>
        {isEmpty(settingOperational) ? null : routing}
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
