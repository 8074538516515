import {cn} from 'utils/css.util';

import {numberFormat} from 'utils/format-number.util';

import {SlipStatus} from 'interfaces/slip.interface';

import Badge from 'components/shared/Badge';
import DataCard from 'components/shared/DataCard';
import {Separator} from 'components/shared/Separator';
import {SlipDepositPrepareStatus, SlipStatus as SlipStatusBadge} from 'components/Slip/SlipStatus';
import {Typography} from 'components/shared/Typography';

/**
 * Container
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.className
 * @param {jsx} props.children
 * @returns {jsx}
 */
const Container = ({id, children, className}) => {
  return (
    <div id={id} className={cn('border rounded-md px-4 py-2', className)}>
      {children}
    </div>
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.type sender/receiver
 * @returns
 */

const ComparisonTable = ({type = 'sender', data}) => {
  return (
    <table className="w-full text-center">
      <thead>
        <tr className="border-b border-gray-500">
          <th className="w-1/3"></th>
          <th className="w-1/3 py-2">
            <span className="text-yellow-400">บัญชีจากสลิป</span>
          </th>
          <th className="w-1/3">
            <span className="text-blue-400">{type === 'sender' ? 'บัญชีผู้เล่นในระบบ' : 'บัญชีรับเงินในระบบ'}</span>
          </th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={item.name + item.slip + index} className="hover:bg-gray-800 ">
              <td className="py-2">{item.name}</td>
              <td>{item.slip}</td>
              <td>{item.system}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const CorrectTransferBadge = ({status, slipAccountNumber, systemAccountNumber}) => {
  if (status === SlipStatus.FAIL) {
    return <Badge variant="warning">ตรวจสอบจากสลิป</Badge>;
  }
  if (slipAccountNumber === systemAccountNumber) {
    return <Badge variant="success">ถูกต้อง</Badge>;
  }

  if (slipAccountNumber !== systemAccountNumber) {
    return <Badge variant="fail">ไม่ถูกต้อง</Badge>;
  }
  return <Badge>-</Badge>;
};

export const SlipComparisonDetail = ({slipRequest}) => {
  const comparisonSenderData = [
    {name: 'เลขบัญชี', slip: slipRequest?.senderAccountNumber || '?', system: slipRequest?.user?.user_bank?.bankId},
    {name: 'ธนาคาร', slip: slipRequest.senderBank || '?', system: slipRequest?.user?.user_bank?.bankName},
  ];
  const comparisonReceiverData = [
    {
      name: 'เลขบัญชี',
      slip: slipRequest?.status === SlipStatus.FAIL ? '?' : slipRequest?.bank?.bankId,
      system: slipRequest?.user?.user_bank?.bank?.bankId,
    },
    {
      name: 'ธนาคาร',
      slip: slipRequest?.status === SlipStatus.FAIL ? '?' : slipRequest?.bank?.bankName,
      system: slipRequest?.user?.user_bank?.bank?.bankName,
    },
  ];

  return (
    <div>
      <Typography className="mb-2 mt-4 text-xl text-amber-500">รายละเอียด</Typography>
      <Separator />

      <Container id="from-account" className="border-yellow-500">
        <Typography className="text-yellow-400">จากบัญชี</Typography>
        <ComparisonTable type={'sender'} data={comparisonSenderData} />
      </Container>

      <div className="my-6" />

      <Container id="to-account">
        <Typography className="text-blue-400">เข้าบัญชี</Typography>
        <ComparisonTable type={'receiver'} data={comparisonReceiverData} />
      </Container>

      <div className="grid grid-cols-1 gap-2 mt-4">
        <DataCard className="flex justify-between items-center">
          <DataCard.Title>สถานะการตรวจสอบสลิป</DataCard.Title>
          <DataCard.Value>
            <SlipStatusBadge status={slipRequest?.status} />
          </DataCard.Value>
        </DataCard>

        <DataCard className="flex justify-between items-center">
          <DataCard.Title>สถานะเตรียมการฝากเงิน</DataCard.Title>
          <DataCard.Value>
            <SlipDepositPrepareStatus status={slipRequest?.depositPrepareStatus} />
          </DataCard.Value>
        </DataCard>

        <DataCard className="flex justify-between items-center">
          <DataCard.Title>โอนถูกบัญชีหรือไม่ ?</DataCard.Title>
          <DataCard.Value>
            <CorrectTransferBadge
              status={slipRequest?.status}
              slipAccountNumber={slipRequest?.bank?.bankId}
              systemAccountNumber={slipRequest?.user?.user_bank?.bank?.bankId}
            />
          </DataCard.Value>
        </DataCard>

        <DataCard className="flex justify-between items-center">
          <DataCard.Title>จำนวนเงิน</DataCard.Title>
          <DataCard.Value>
            <div className="space-x-2">
              <span className="text-green-500">{numberFormat(slipRequest?.amount, '0.00')}</span>
              <span>บาท</span>
            </div>
          </DataCard.Value>
        </DataCard>

        <DataCard className="flex justify-between items-center">
          <DataCard.Title>เวลาธุรกรรม</DataCard.Title>
          <DataCard.Value>
            {slipRequest?.status === SlipStatus.FAIL ? '-' : slipRequest?.transactionDate}
          </DataCard.Value>
        </DataCard>
        {/* <DataCard className="flex justify-between items-center">
          <DataCard.Title>เวลาทำรายการในระบบ</DataCard.Title>
          <DataCard.Value>{moment(slipRequest?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</DataCard.Value>
        </DataCard> */}
      </div>
    </div>
  );
};
