export function afWithdrawSummary(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/affiliate/withdraw/summary`,
    data: payload,
  };
}

export function rewardSummary(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/report/reward/summary`,
    data: payload,
  };
}

export function cashLimitBalance({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/dashboard/cash/limit-balance',
    cancelToken,
  };
}
