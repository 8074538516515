import {createSlice} from '@reduxjs/toolkit';

export const DialogView = {
  // * For Slip
  APPROVE_SLIP_REQUEST: 'Approve slip request',
  REJECT_SLIP_REQUEST: 'Reject slip request',
  MANUAL_SLIP_REQUEST: 'Manual slip request',
  NOT_FOUND_SLIP_REQUEST: 'Not found slip request',

  // * For Transfer
  KBIZ_SCAN_QR_CODE_TRANSFER: 'kbiz scan qr code transfer',
  // * For Configuration
  UPDATE_HOME_CONFIGURATION: 'Update home configuration',
};

const reducers = {
  /**
   * Open dialog and set dialog view
   * @param {DialogView} action
   */
  openDialog: (state, action) => {
    state.displayDialog = true;
    state.dialogView = action.payload;
  },

  /**
   * Close dialog
   */
  closeDialog: state => {
    state.displayDialog = false;
  },

  /**
   * Set dialog max width
   * @param {'xs' | 'sm' | 'md' | 'lg' | 'xl' | ''} action
   */
  setDialogMaxWidth: (state, action) => {
    state.dialogMaxWidth = action.payload;
  },

  /**
   * Set dialog click outside to close
   * @param {boolean} action
   */
  setDialogClickOutsideToClose: (state, action) => {
    state.dialogClickOutsideToClose = action.payload;
  },
};

const initialState = {
  displayDialog: false,
  dialogView: DialogView.APPROVE_SLIP_REQUEST,
  dialogMaxWidth: 'sm', // "sm" | "xs" | "md" | "lg" | "xl" | ""
  dialogClickOutsideToClose: true,
};

export const slice = createSlice({
  name: 'uiProvider',
  initialState,
  reducers,
});

export const {openDialog, closeDialog, setDialogMaxWidth, setDialogClickOutsideToClose} = slice.actions;

export default slice.reducer;
