import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {creditCancel, creditConfirm, creditRequestList} from 'containers/Reward/apiHelper';

const name = 'reward2';

const initialState = {
  things: {},
  credits: {},
};

export const fetchCreditConfirm = createAsyncThunk('reward2/fetchCreditConfirm', async arg => {
  const response = await api(creditConfirm(arg));
  return response.data;
});

export const fetchCreditCancel = createAsyncThunk('reward2/fetchCreditCancel', async arg => {
  const response = await api(creditCancel(arg));
  return response.data;
});

export const fetchCreditRequestList = createAsyncThunk('reward2/fetchCreditRequestList', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(creditRequestList({cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchCreditRequestList.pending]: (state, action) => {
    state.credits = {...action.payload, loading: true};
  },
  [fetchCreditRequestList.fulfilled]: (state, action) => {
    state.credits = {...action.payload, loading: false};
  },
};

export const reward2Slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = reward2Slice.actions;

export default reward2Slice.reducer;
