export function creditRequestList({cancelToken}) {
  return {
    endpoint: `secure/reward/request/1`,
    cancelToken,
  };
}

export function thingRequestList({cancelToken}) {
  return {
    endpoint: `secure/reward/request/2`,
    cancelToken,
  };
}

export function creditConfirm(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/reward/credit-confirm`,
    data: payload,
  };
}

export function creditCancel(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/reward/cancel`,
    data: payload,
  };
}
