import clsx from 'clsx';
import config from '../../configs';
import Logo from 'components/Logo';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grow,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {disconnectSocket} from 'contexts/Socket';
// import {getSettingOperational} from 'store/actions/settingAction';
import {makeStyles} from '@material-ui/core/styles';
import {Menu as MenuIcon, Person as PersonIcon, Settings as SettingIcon} from '@material-ui/icons';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {signOut} from 'store/actions/authActions';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.grey1,
  },
  avatar: {
    width: 60,
    height: 60,
  },
  textColor: {
    color: 'white',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({className, onMobileNavOpen, ...rest}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(state => state.auth.currentUser);
  const [openSetting, setOpenSetting] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const anchorRefSetting = useRef(null);
  const anchorRefUser = useRef(null);

  const handleToggleSetting = () => {
    setOpenSetting(prevOpen => !prevOpen);
  };

  const handleCloseSetting = event => {
    if (anchorRefSetting.current && anchorRefSetting.current.contains(event.target)) {
      return;
    }

    setOpenSetting(false);
  };

  function handleListSettingKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSetting(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenSetting = useRef(openSetting);
  useEffect(() => {
    if (prevOpenSetting.current === true && openSetting === false) {
      anchorRefSetting.current.focus();
    }

    prevOpenSetting.current = openSetting;
  }, [openSetting]);

  const handleToggleUser = () => {
    setOpenUser(prevOpen => !prevOpen);
  };

  const handleCloseUser = event => {
    if (anchorRefUser.current && anchorRefUser.current.contains(event.target)) {
      return;
    }

    setOpenUser(false);
  };

  function handleListUserKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenUser(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenUser = useRef(openUser);

  useEffect(() => {
    if (prevOpenUser.current === true && openUser === false) {
      anchorRefUser.current.focus();
    }

    prevOpenUser.current = openUser;
  }, [openUser]);

  const onSignOut = async e => {
    handleCloseUser(e);
    // console.log('click logout');
    disconnectSocket();
    signOut(dispatch);
  };

  const handleNavigate = link => {
    navigate(link);
  };

  const routeLink = config.navBarRoutes.filter(
    route =>
      (currentUser?.rReport && route.permission === 'rReport') ||
      (currentUser?.rUser && route.permission === 'rUser') ||
      (currentUser?.rDeposit && route.permission === 'rDeposit') ||
      (currentUser?.rWithdraw && route.permission === 'rWithdraw') ||
      (currentUser?.rxReward && route.permission === 'rxReward') ||
      (currentUser?.rAdmin && route.permission === 'rAdmin') ||
      (currentUser?.lv === 0 && route.permission === '')
  );

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Container>
        <Toolbar style={{padding: 0}}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Hidden xsDown>
            <Box p={2}>
              {routeLink?.map((route, index) => (
                <Button key={index} className={classes.textColor} onClick={() => handleNavigate(route.href)}>
                  {route.title}
                </Button>
              ))}
            </Box>
          </Hidden>
          <Box flexGrow={1} />
          <Hidden smDown>
            {currentUser?.lv === 0 && (
              <Button
                ref={anchorRefSetting}
                aria-controls={openSetting ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggleSetting}
                style={{marginRight: 20}}
                color="inherit">
                <SettingIcon style={{color: 'white', margin: '0 5px 0 5px'}} />
                <Typography variant="h5" color="primary">
                  ตั้งค่า
                </Typography>
              </Button>
            )}
            <Popper open={openSetting} anchorEl={anchorRefSetting.current} role={undefined} transition disablePortal>
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseSetting}>
                      <MenuList autoFocusItem={openSetting} id="menu-list-grow" onKeyDown={handleListSettingKeyDown}>
                        <MenuItem
                          onClick={e => {
                            handleCloseSetting(e);
                            navigate('/admin/setting');
                          }}>
                          ทั่วไป
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            handleCloseSetting(e);
                            navigate('/admin/setting/customization');
                          }}>
                          การปรับแต่ง
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            handleCloseSetting(e);
                            navigate('/admin/setting/wheel');
                          }}>
                          วงล้อ
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            handleCloseSetting(e);
                            navigate('/admin/setting/reward');
                          }}>
                          ของรางวัล
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            handleCloseSetting(e);
                            navigate('/admin/setting/bank');
                          }}>
                          ธนาคาร
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            handleCloseSetting(e);
                            navigate('/admin/setting/game');
                          }}>
                          เกมส์
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            handleCloseSetting(e);
                            navigate('/admin/setting/announcement');
                          }}>
                          แจ้งข่าวสาร
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Button
              ref={anchorRefUser}
              aria-controls={openUser ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggleUser}
              color="inherit">
              <PersonIcon style={{color: 'white', margin: '0 5px 0 5px'}} />
              <Typography variant="h5" color="primary">
                {currentUser?.name}
              </Typography>
            </Button>
            <Popper open={openUser} anchorEl={anchorRefUser.current} role={undefined} transition disablePortal>
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseUser}>
                      <MenuList autoFocusItem={openUser} id="menu-list-grow" onKeyDown={handleListUserKeyDown}>
                        <MenuItem onClick={onSignOut}>ออกจากระบบ</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Hidden>
          <Hidden mdUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon style={{color: 'white'}} />
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
