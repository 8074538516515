import {REWARD_CATEGORY} from '../../constants';

export function rewardCredit(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/secure/reward/history/${REWARD_CATEGORY.CREDIT}`,
    data: payload,
    cancelToken,
  };
}

export function rewardThing(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/secure/reward/history/${REWARD_CATEGORY.THING}`,
    data: payload,
    cancelToken,
  };
}

export function rewardWheel(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/secure/wheel/history`,
    data: payload,
    cancelToken,
  };
}
