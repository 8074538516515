/** Configuration */
export function configurationList({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/configuration/list',
    cancelToken,
  };
}

export function configurationByKey(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/secure/configuration/key',
    cancelToken,
    params: {
      key: payload.key,
    },
  };
}

export function updateConfiguration(payload, {cancelToken}) {
  let data;

  if (payload?.formData) {
    data = payload.formData;
  } else {
    // eslint-disable-next-line no-unused-vars
    const {uuid, ...newData} = payload;
    data = newData;
  }

  return {
    method: 'PATCH',
    endpoint: `/secure/configuration/update/${payload.uuid}`,
    cancelToken,
    data,
  };
}
