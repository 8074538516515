import api from 'api/api';
import axios from 'axios';
import {
  addManualCredit,
  fail,
  list,
  listDepositManual,
  nearbyDepositTransactionByUserUuid,
  notfound,
  pull,
  pullManualCredit,
  search,
  timeout,
  updatePoint,
} from 'containers/Deposit/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
  search: {},
  list: {},
  listManual: {},
  data: {},
  nearbyDepositTransaction: {},
};

export const fetchDepositSearch = createAsyncThunk('deposit/fetchSearch', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(search(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchDepositList = createAsyncThunk('deposit/fetchList', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(list(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchDepositFail = createAsyncThunk('deposit/fetchFail', async arg => {
  const response = await api(fail(arg));
  return response.data;
});

export const fetchDepositNotfound = createAsyncThunk('deposit/fetchNotFound', async arg => {
  const response = await api(notfound(arg));
  return response.data;
});

export const fetchDepositTimeout = createAsyncThunk('deposit/fetchTimeout', async arg => {
  const response = await api(timeout(arg));
  return response.data;
});

export const fetchDepositPull = createAsyncThunk('deposit/fetchPull', async arg => {
  const response = await api(pull(arg));
  return response.data;
});

export const fetchDepositAddManualCredit = createAsyncThunk('deposit/fetchAddManualCredit', async arg => {
  const response = await api(addManualCredit(arg));
  return response.data;
});

export const fetchDepositPullManualCredit = createAsyncThunk('deposit/fetchPullManualCredit', async arg => {
  const response = await api(pullManualCredit(arg));
  return response.data;
});

export const fetchDepositUpdatePoint = createAsyncThunk('deposit/fetchUpdatePoint', async arg => {
  const response = await api(updatePoint(arg));
  return response.data;
});

export const fetchDepositListManual = createAsyncThunk('deposit/fetchDepositListManual', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(listDepositManual(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchNearbyDepositTransactionByUserUuid = createAsyncThunk(
  'deposit/fetchNearbyDepositTransactionByUserUuid',
  async (arg, {signal}) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    const response = await api(nearbyDepositTransactionByUserUuid(arg, {cancelToken: source.token}));
    return response.data;
  }
);

const extraReducers = {
  [fetchDepositSearch.pending]: (state, action) => {
    state.search = {...action.payload, loading: true};
  },
  [fetchDepositSearch.fulfilled]: (state, action) => {
    state.search = {...action.payload, loading: false};
  },
  [fetchDepositList.pending]: (state, action) => {
    state.list = {...action.payload, loading: true};
  },
  [fetchDepositList.fulfilled]: (state, action) => {
    state.list = {...action.payload, loading: false};
  },
  [fetchDepositListManual.pending]: (state, action) => {
    state.listManual = {...action.payload, loading: true};
  },
  [fetchDepositListManual.fulfilled]: (state, action) => {
    state.listManual = {...action.payload, loading: false};
  },
  [fetchDepositNotfound.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchDepositNotfound.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
  [fetchDepositTimeout.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchDepositTimeout.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
  [fetchDepositAddManualCredit.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchDepositAddManualCredit.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
  [fetchNearbyDepositTransactionByUserUuid.pending]: (state, action) => {
    state.nearbyDepositTransaction = {...action.payload, loading: true};
  },
  [fetchNearbyDepositTransactionByUserUuid.fulfilled]: (state, action) => {
    state.nearbyDepositTransaction = {...action.payload, loading: false};
  },
};

const name = 'deposit';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setList: (state, action) => {
      state.list.data = action.payload;
    },
    resetManualList: state => {
      state.listManual = {};
    },
    setManualList: (state, action) => {
      state.listManual = {data: action.payload};
    },
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setList, resetManualList, setManualList} = slice.actions;

export default slice.reducer;
