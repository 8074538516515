import appReducer from 'store/reducers/rootReducer';
import setAuthorizationToken from 'utils/setAuthorizationToken';
import {configureStore} from '@reduxjs/toolkit';
// import {setupListeners} from '@reduxjs/toolkit/query';
// import {withdrawNotifyApi} from 'store/apis/socket.api';

const rootReducer = (state, action) => {
  if (action.type === 'Logout') {
    setAuthorizationToken();
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const preloadedState = {
  auth: {
    currentUser: JSON.parse(localStorage.getItem('user')) || {},
  },
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // thunk: {
      //   extraArgument: withdrawNotifyApi.middleware,
      // },
      serializableCheck: false,
    }),
});

// setupListeners(store.dispatch);

export default store;
