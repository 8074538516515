export const SlipStatus = {
  VERIFIED: 'VERIFIED',
  FAIL: 'FAIL',
};

export const SlipDepositPrepareStatus = {
  WAITING: 'WAITING',
  NOT_FOUND: 'NOT FOUND',
  READY: 'READY',
};
