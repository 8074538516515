import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {Button, makeStyles} from '@material-ui/core';

import {popup} from 'utils/common.util';

import {closeDialog} from 'store/uiProvider.slice';
import {fetchConfigurationList, fetchUpdateConfiguration, resetJustConfiguration} from 'store/configuration.slice';

import Spinner from 'components/shared/Spinner';
import UploadDragFile2 from 'components/shared/Upload/UploadDragFile2';
import {Separator} from 'components/shared/Separator';
import {Typography} from 'components/shared/Typography';

// eslint-disable-next-line no-undef
const {REACT_APP_CLOUDFRONT_URL} = process.env;

export const useStyles = makeStyles(() => ({
  approveBtn: {
    background: '#39A6A3',
    color: 'white',
  },
  notFoundBtn: {
    background: '#ffa100',
    color: 'white',
  },
  manualBtn: {
    background: '#B23CFD',
    color: 'white',
  },
  rejectBtn: {
    backgroundColor: '#9A070B',
    color: 'white',
  },
  disableBtn: {
    background: '#696969',
    color: 'white',
  },
}));

const UpdateHomeCustomization = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {Upload, selectedFiles, setSelectedFiles} = UploadDragFile2();

  const configuration = useSelector(state => get(state, 'configuration.configuration', {}));

  const [isLoading, setIsLoading] = useState(false);
  const [imageIsInvalid, setImageIsInvalid] = useState(false);

  const handleUpdateConfiguration = async () => {
    const homeImage = selectedFiles[0];
    if (!homeImage) {
      return popup({
        icon: 'info',
        title: 'กรุณาอัพโหลดรูป',
      });
    }

    const formData = new FormData();
    formData.append('file', homeImage);

    try {
      setIsLoading(true);

      const response = await dispatch(fetchUpdateConfiguration({formData, uuid: configuration.uuid})).unwrap();

      popup({
        icon: 'success',
        title: response.message,
      });

      dispatch(closeDialog());
      return null;
    } catch (err) {
      let message = err?.response?.data?.message || err?.data?.message || 'เกิดข้อผิดพลาดในการอัพโหลด';

      return popup({
        icon: 'error',
        title: message,
      });
    } finally {
      await dispatch(fetchConfigurationList());
      setIsLoading(false);
    }
  };

  const handleImageError = () => {
    setImageIsInvalid(true);
  };

  const handleReset = () => {
    setSelectedFiles([]);
  };

  const handleCloseDialog = () => {
    setSelectedFiles([]);
    dispatch(closeDialog());
  };

  useEffect(() => {
    return () => {
      setImageIsInvalid(false);
      dispatch(resetJustConfiguration());
    };
  }, []);

  return (
    <div>
      <Typography className="space-x-2 text-xl">
        <span>แก้ไข</span>
        <span className="text-yellow-400">{get(configuration, 'displayName', '')}</span>
      </Typography>

      {isLoading ? (
        <div className="flex flex-col justify-between items-center py-10">
          <h1>กรุณารอสักครู่ ระบบกำลังเนินการ . . .</h1>
          <Spinner />
        </div>
      ) : (
        <div className="mt-4">
          <div className="space-y-2 mb-4">
            <Typography>ปัจจุบัน</Typography>
            <Separator />

            {imageIsInvalid ? (
              <div className="text-center w-full px-2 py-4 rounded-md ">
                <div className="text-gray-400">
                  <h1>ยังไม่มีรูปภาพ</h1>
                  <span>{configuration?.value?.data?.width}</span>
                  <span>x</span>
                  <span>{configuration?.value?.data?.height}</span>
                </div>
              </div>
            ) : (
              <img
                src={REACT_APP_CLOUDFRONT_URL + '/' + configuration?.value?.data?.path}
                className=""
                alt={get(configuration, 'displayName', '')}
                onError={() => handleImageError()}
              />
            )}
          </div>

          <div className="space-y-2 mb-4">
            <Typography>อัพโหลดรูป</Typography>
            <Separator />
            <Upload handleReset={handleReset} disabled={isLoading} />
          </div>

          <div className="flex justify-between">
            <Button className={classes.rejectBtn} onClick={handleCloseDialog}>
              ยกเลิก
            </Button>
            <Button className={classes.approveBtn} onClick={handleUpdateConfiguration}>
              ยืนยันการแก้ไข
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateHomeCustomization;
