import {CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    animationDuration: '0.75s',
  },
}));

const Spinner = props => {
  const classes = useStyles();
  return <CircularProgress className={classes.root} size={40} thickness={3} {...props} value={100} />;
};

export default Spinner;
