export default {
  CLIENT_ID: 'GIMI88',
  firebaseConfig: {
    apiKey: 'AIzaSyC347ABrUFowKSMpSEkkfV0Z-y2f84Jyc4',
    authDomain: 'gimi-web-agent-v2.firebaseapp.com',
    projectId: 'gimi-web-agent-v2',
    storageBucket: 'gimi-web-agent-v2.appspot.com',
    messagingSenderId: '132307404856',
    appId: '1:132307404856:web:19781f8c76f522fa0afecd',
    measurementId: 'G-TX2X0Z30KS',
  },
};
